import { COLOR, useThemeContext } from "contexts/theme"
import { BlokComponent, WithLokalizeKey } from "services/model/storyblok"
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";
import classnames from "classnames";
import { ClubStore, InstructorStore } from "services/store";
import { useStore } from "services/hooks/useStore";
import { useTranslationsContext } from "contexts/translations";
import { storyblokEditable } from "@storyblok/js";

interface StoryblokProps extends WithLokalizeKey {
  background_color?: COLOR
  Key: "Next_Class_In" | "Next_Class_By"
}

export const NextClassButton: BlokComponent = ({ blok }) => {

  const appBlok = blok as unknown as StoryblokProps

  const { getColor } = useThemeContext();
  const { translate } = useTranslationsContext();
  const style: React.CSSProperties = {};

  if (appBlok.background_color) {
    style.backgroundColor = getColor(appBlok.background_color as COLOR);
  }

  const buttonClassName = classnames("w-full",
    "h-[68px]",
    "pt-[12px]",
    "pr-[14px]",
    "pl-[19px]",
    "skew-x-[-12deg]",
    "rounded",
    "shadow-[0_3px_8px_-0_rgba(0,0,0,0.5)]",
    `bg-[${style.backgroundColor}]`)

  const club = useStore(ClubStore);
  const instructor = useStore(InstructorStore);

  const onChooseClubClick = () => {
    WebviewBridge.post(EVENT_KEY.VIEW_NEXT_CLASS_IN);
  };

  const onChooseInstructorClick = () => {
    WebviewBridge.post(EVENT_KEY.VIEW_NEXT_CLASS_BY);
  };

  function handleClickEvent(key: string) {
    if (key === "Next_Class_In") {
      onChooseClubClick()
    }
    if (key === "Next_Class_By") {
      onChooseInstructorClick()
    }
  }


  function NextClassSubtitle(key: string) {
    if (key === "Next_Class_In") {
      return <h3 className="text-[#F2F2F2] skew-x-[12deg] capitalize text-center">{club?.clubWebsiteName}</h3>
    }
    if (key === "Next_Class_By") {
      return <h3 className="text-[#F2F2F2] skew-x-[12deg] capitalize text-center" >{instructor?.name}</h3>
    }
  }


  return <>
    <div
      {...storyblokEditable(blok)}
      className={buttonClassName}
      onClick={() => handleClickEvent(appBlok.Key)}>
      <p className="text-[#F2F2F2] skew-x-[12deg] uppercase text-center font-extrabold">{translate(appBlok.lokalize_key)}</p>
      {NextClassSubtitle(appBlok.Key)}
    </div>

  </>
}