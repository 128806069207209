import { CarouselBanner } from "services/model/carouselBanner";

export const carouselBanner_mock: Array<CarouselBanner> = [
    {
        title: "Refer a friend",
        subtitle: "How about bringing a friend next time?",
        type: "refer_a_friend"
    },
    {
        title: "Virtual Classes",
        subtitle: "Fitness Anywhere with over 1,500 videos",
        type: "vod"
    }
]