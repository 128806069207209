import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import classnames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useTranslationsContext } from "contexts/translations";
import { useMemo } from "react";
import { getAssetUrl } from "services/blockData";
import { useStore } from "services/hooks/useStore";
import { BlokComponent, Dependency, StoryblokAsset, WithParentDependencies } from "services/model/storyblok";
import { MarketplaceStore } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";
import  crown  from "styles/icons/crown.svg"

interface StoryblokProps {
    label: string
    font: string
    key: string
    text_color?: COLOR
    border_color?: COLOR
    background_image?: StoryblokAsset
    col_span?: number
    row_span?: number
    alignment: "normal" | "self-start" | "self-end" | "center"
}
type CardItem = {
    eventParam?: string
    eventKey: EVENT_KEY
    displayValue?: string
    icon?: boolean 
    isActive?: boolean
}

const useMarketPlaceCardItem = (key?: string, parentDependencies?: Array<Dependency>): CardItem | undefined => {
    const marketPlaceItems = useStore(MarketplaceStore)

    return useMemo(() => {
        if (parentDependencies?.includes(Dependency.MARKETPLACE)) {
            const marketPlaceItem = marketPlaceItems?.find(({ id }) => id === parseInt(key ?? ""))
            return {
                eventKey: EVENT_KEY.VIEW_MARKETPLACE_ITEM,
                eventParam: marketPlaceItem?.title,
                displayValue: marketPlaceItem?.title,
                icon: marketPlaceItem?.icon ?? false,
                isActive: marketPlaceItem?.isActive ?? true
            }
        }

    }, [key, parentDependencies, marketPlaceItems])


}

export const MarketPlaceCard: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
    const appBlok = blok as unknown as StoryblokProps

    const item = useMarketPlaceCardItem(appBlok.key, parentDependencies)
    const { getColor } = useThemeContext();
    const { translate } = useTranslationsContext();

    const textColor = getColor(appBlok.text_color);
    const borderColor = getColor(appBlok.border_color);
    const backgroundImageUrl = getAssetUrl(appBlok)

    const className = classnames(
        "rounded-md",
        "flex",
        "flex-col",
        "items-start",
        "justify-end",
        "gap-2",
        "p-3",
        "flex-1",
        "h-full",
        "relative",
        `text-[${textColor}]`,
        `bg-[url(${backgroundImageUrl})]`,
        "bg-center",
        "bg-cover",
        "shadow-[0_5px_10px_0px_rgba(0,0,0,0.3)]"

    );

    const onClick = () => {
        if (item && item.isActive) {
          WebviewBridge.post(item.eventKey, item.eventParam);
        } else {
          WebviewBridge.post(EVENT_KEY.DEBUG, `NO MARKETPLACE  CARD ITEM FOUND FOR ${appBlok.label} OR THIS ITEM IS INACTIVE`);
        }
      }
      if (!item?.displayValue) {
        return null
      }

    return (
        <>
            {item ? (
                <div
                {...storyblokEditable(blok)}
                className={classNames(appBlok.col_span && `col-span-${appBlok.col_span}`, appBlok.row_span && `row-span-${appBlok.row_span}`, `justify-${appBlok.alignment}`, `relative`, "pr-4")}
              >
                <div
                    {...storyblokEditable(blok)}
                    onClick={onClick}
                    className={className}
                >
                    {item.icon ? <img src={crown} alt="" className="w-11 h-8 p-[2px] bg-[#FFF0D3] rounded absolute top-3 right-3 "/>: null}
                    {!item.isActive ? ( 
                    <>
                    <div className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-60"> </div>
                    <div className= {`bg-[#C8102E] py-[3px] px-[10px] rounded-lg opacity-100 z-10 fitness-first-medium my-0 mx-[auto]`}> Coming Soon </div>
                    
                    </>
                    ) : null}
                    <span className={`${appBlok.font} uppercase text-lg`}>{appBlok.label ? appBlok.label : `${translate(item?.displayValue)}`}</span>
                    <div className={`w-[60px] h-[2px] bg-[${borderColor}] bottom-[6px] left-[6px]`} />
                </div>
                </div>) : null
            }
        </>
    )

}
