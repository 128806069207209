import { ColorMode } from "contexts/theme";
import { BehaviorSubject } from "rxjs";
import { CarouselBanner } from "services/model/carouselBanner";
import { Club } from "services/model/club";
import { COUNTRY_CODE } from "services/model/country";
import { HeaderNotification } from "services/model/headerNotification";
import { Instructor } from "services/model/instructor";
import { TRANSLATION_FILE_CODE } from "services/model/language";
import { LatestNewsItem } from "services/model/latestNews";
import { MarketplaceItem } from "services/model/marketplace";
import { Topic } from "services/model/topic";
import { Member, MemberTier } from "../model/member";
import { EVENT_KEY, WebviewBridge } from "../webviewBridge";

export class _AppSubject<T> {
  subject = new BehaviorSubject<T|undefined>(undefined);
  set = (data: T) => {
    this.subject.next(data);
  };
  get = (setState: (props: T|undefined) => void) => {
    this.subject.subscribe(setState);
  };
}

export const ColorModeStore = new _AppSubject<ColorMode>();
export const SkipUserCountryStore = new _AppSubject<COUNTRY_CODE>();
export const MemberStore = new _AppSubject<Member>();
export const MemberTierStore = new _AppSubject<MemberTier>();
export const HeaderNotificationStore = new _AppSubject<Array<HeaderNotification>>();
export const ClubStore = new _AppSubject<Club>();
export const InstructorStore = new _AppSubject<Instructor>();
export const CarouselBannerStore = new _AppSubject<Array<CarouselBanner>>();
export const LatestNewsStore = new _AppSubject<Array<LatestNewsItem>>();
export const MarketplaceStore = new _AppSubject<Array<MarketplaceItem>>();
export const TopicsStore = new _AppSubject<Array<Topic>>();
export const LanguageStore = new _AppSubject<TRANSLATION_FILE_CODE>();

const debugPostMessage = (json: any, key: string, debug?: boolean) => {
  if (debug) {
    WebviewBridge.post(EVENT_KEY.DEBUG, `${key} : ${JSON.stringify(json)}`);
  }
};

export const setLanguage = (code: TRANSLATION_FILE_CODE, debug?: boolean) => {
  LanguageStore.set(code);
};

export const setSkipUserCountryCallbackName = "setSkipUserCountry"
const setSkipUserCountry = (country: COUNTRY_CODE, debug?: boolean) => {
  debugPostMessage(country, 'setSkipUserCountry', debug);
  SkipUserCountryStore.set(country)
};
window.setSkipUserCountry = setSkipUserCountry;

export const setTopicsCallbackName = "setTopics"
const setTopics = (topics: Array<Topic>, debug?: boolean) => {
  debugPostMessage(topics, 'setTopics', debug);
  TopicsStore.set(topics);
};
window.setTopics = setTopics;

export const setColorModeCallbackName = "setColorMode"
const setColorMode = (str: ColorMode, debug?: boolean) => {
  debugPostMessage(str, 'setColorMode', debug);
  ColorModeStore.set(str);
};
window.setColorMode = setColorMode;

export const setMemberCallbackName = "setMember"
const setMember = (json: Member, debug?: boolean) => {
  debugPostMessage(json, 'setMember', debug);
  MemberStore.set(json);
};
window.setMember = setMember;

export const setMemberTierCallbackName = "setMemberTier"
const setMemberTier = (json: MemberTier, debug?: boolean) => {
  debugPostMessage(json, 'setMemberTier', debug);
  MemberTierStore.set(json);
};
window.setMemberTier = setMemberTier;

export const setHeaderNotificationCallbackName = "setHeaderNotification"
const setHeaderNotification = (json: Array<HeaderNotification>, debug?: boolean) => {
  debugPostMessage(json, 'setHeaderNotification', debug);
  HeaderNotificationStore.set(json);
};
window.setHeaderNotification = setHeaderNotification;

export const setClubCallbackName = "setClub"
const setClub = (json: Club, debug?: boolean) => {
  debugPostMessage(json, 'setClub', debug);
  ClubStore.set(json);
};
window.setClub = setClub;

export const setInstructorCallbackName = "setInstructor"
const setInstructor = (json: Instructor, debug?: boolean) => {
  debugPostMessage(json, 'setInstructor', debug);
  InstructorStore.set(json);
};
window.setInstructor = setInstructor;

export const setCarouselBannerCallbackName = "setCarouselBanner"
const setCarouselBanner = (json: Array<CarouselBanner>, debug?: boolean) => {
  debugPostMessage(json, 'setCarouselBanner', debug);
  CarouselBannerStore.set(json);
};
window.setCarouselBanner = setCarouselBanner;

export const setLatestNewsCallbackName = "setLatestNews"
const setLatestNews = (json: Array<LatestNewsItem>, debug?: boolean) => {
  debugPostMessage(json, 'setLatestNews', debug);
  LatestNewsStore.set(json);
};
window.setLatestNews = setLatestNews;

export const setMarketplaceItemsCallbackName = "setMarketplaceItems"
const setMarketplaceItems = (json: Array<MarketplaceItem>, debug?: boolean) => {
  debugPostMessage(json, 'setMarketplaceItems', debug);
  MarketplaceStore.set(json);
};
window.setMarketplaceItems = setMarketplaceItems;

