import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useTranslationsContext } from "contexts/translations";
import { useStore } from "services/hooks/useStore";
import { BlokComponent, WithLokalizeKey } from "services/model/storyblok";
import { ClubStore } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";
import NavigateNext from "styles/icons/navigate_next_black_24dp.svg"
import FF from "styles/images/FF.svg"
import CF from "styles/images/CF.svg"

interface StoryblokProps extends WithLokalizeKey {
  text_color?: string
  background_color?: string
  font: string
}

export const NextClassIn: BlokComponent = ({ blok }) => {
  const appBlok = blok as unknown as StoryblokProps

  const club = useStore(ClubStore);
  const {translate} = useTranslationsContext()

  const onChooseClubClick = () => {
    WebviewBridge.post(EVENT_KEY.VIEW_NEXT_CLASS_IN);
  };

  const buttonClassName = classNames(
    "shadow-[0_3px_8px_-0_rgba(0,0,0,0.24)]",
    "rounded-lg",
    "flex",
    "items-center",
    "p-4",
    "h-full"
  );

  const { getColor } = useThemeContext();

  const style: React.CSSProperties = {};

  if (appBlok.background_color) {
    style.backgroundColor = getColor(appBlok.background_color as COLOR);
  }

  if (appBlok.text_color) {
    style.color = getColor(appBlok.text_color as COLOR);
  }

  return (
    <div
      {...storyblokEditable(blok)}
      onClick={onChooseClubClick}
      className={buttonClassName}
      style={style}
    >
      <div className={`uppercase mr-4 ${appBlok.font}`}>{translate(appBlok.lokalize_key)}</div>
      <div className="w-5 h-5 mr-4">
        {club?.brandCode === "ff" ?
        <img src={FF} alt="" /> :
        <img src={CF} alt="" />

        }
      </div>
      {club ? (
        <div className="text-lg grow">
          {club.clubWebsiteName.length > 15 ? (
        <span>{club.clubWebsiteName.substring(0, 15)}...</span>
      ) : (
        <span>{club.clubWebsiteName}</span>
      )}
          </div>
      ) : (
        <div className="text-lg grow text-[#B2B2B2]">{translate("choose_a_club")}</div>
      )}
      <img src={NavigateNext} alt="" />
    </div>
  );
};
