import { useStoryblok } from "@storyblok/react";
import { useSearchParams, useLocation } from "react-router-dom";
import { NATIVE_LANGUAGE_CODE, STORYBLOK_LANGUAGE, TRANSLATION_FILE_CODE } from "services/model/language";
import { setLanguage } from "services/store";
import { mapNativeToStoryblokLanguage, mapNativeToTranslationLanguage } from "services/translations";

const applyBrandDomId = (pathnameArray: Array<string>) => {
    const root = document.getElementById('root')
    if (root && pathnameArray?.length) {
      root.classList.add(`${pathnameArray[0]}`)
    }
}

export const useStoryContent = () => {
  const location = useLocation()
  let storyblokLanguage = STORYBLOK_LANGUAGE.ENGLISH;
  let translationsLanguage = TRANSLATION_FILE_CODE.ENGLISH;
  let pathnameArray = location.pathname?.split('/')?.filter((t) => t !== '' && t !== "#" && t !== "demo") ?? [];

  const [searchParams] = useSearchParams();

  applyBrandDomId(pathnameArray)

  const languages = Object.values(NATIVE_LANGUAGE_CODE).map(v => v as string)

  const params = searchParams.get('language') ?? "NO_LG"

  if (params && languages.includes(params)) {
    storyblokLanguage = mapNativeToStoryblokLanguage(params as NATIVE_LANGUAGE_CODE);
    translationsLanguage = mapNativeToTranslationLanguage(params as NATIVE_LANGUAGE_CODE)
  }

  setLanguage(translationsLanguage)

  return useStoryblok(pathnameArray.join('/'), {
    version: 'draft', // Or published
    language: storyblokLanguage,
  });
}