import {useRef, useEffect} from 'react'
export const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLElement>(null);
  
    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      };
  
      document.addEventListener('click', handleClick, true);
  
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
  
    return ref;
  };