import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react";
import classNames from "classnames";
import { COLOR } from "contexts/theme";
import { BlokComponent, WithParentDependencies } from "services/model/storyblok";

interface StoryblokProps {
  row_height?: string
  column_width?: string
  background_color?: COLOR
  horizontal_gap?: string
  vertical_gap?: string
  columns_number?: string
  rows_number?: string
  cards: Array<SbBlokData>
}

export const Grid: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
  const appBlok = blok as unknown as StoryblokProps
  
  const gridStyle: React.CSSProperties = {};
  let gridConfiguration = classNames("grid", "w-full", "px-4");

  const rowHeight = parseInt(appBlok.row_height ?? '0')
  const rowHeightValue = rowHeight ? `${rowHeight}px` : '1fr'
  const gridAutoRows = `auto-rows-[${rowHeightValue}]`

  const colWidth = parseInt(appBlok.column_width ?? '0')
  const colWidthValue = colWidth ? `${colWidth}px` : '1fr'
  const gridAutoCols = `auto-cols-[${colWidthValue}]`

  const colNumber = parseInt(appBlok.columns_number ?? '0')
  let gridCols = ''
  if (colNumber > 1) {
    gridCols = `grid-cols-[repeat(${colNumber},${colWidthValue})]`
  }
  if (colNumber === 1) {
    gridCols = 'grid-flow-row'
  }

  const rowNumber = parseInt(appBlok.rows_number ?? '0')
  let gridRows = ''
  if (rowNumber > 1) {
    gridRows = `grid-rows-[repeat(${rowNumber},${rowHeightValue})]`
  }
  if (rowNumber === 1) {
    gridRows = 'grid-flow-col'
  }

  gridConfiguration = classNames(gridConfiguration, gridAutoRows, gridAutoCols, gridCols, gridRows);

  // Grid Gap Configuration
  if (appBlok.horizontal_gap) {
    gridConfiguration = classNames(gridConfiguration, `gap-x-[${appBlok.horizontal_gap}px]`);
  }

  if (appBlok.vertical_gap) {
    gridConfiguration = classNames(gridConfiguration, `gap-y-[${appBlok.vertical_gap}px]`);
  }

  // Grid Background Color
  if (appBlok.background_color) {
    gridStyle.backgroundColor = `${appBlok.background_color}`;
  }
  
  return (
    <section {...storyblokEditable(blok)} className={gridConfiguration} style={gridStyle}>
      {appBlok.cards?.length ? (
        appBlok.cards
          .map(nestedBlok => (
            <StoryblokComponent
              blok={nestedBlok}
              parentDependencies={parentDependencies}
              key={nestedBlok._uid}
            />
          ))
      ) : (
        <span>No Cards</span>
      )}
    </section>
  );
};
