import { SbBlokData } from "@storyblok/js";
import { Component } from "./global";

export interface BlokComponent<T = {}, E = {}> extends Component<T & {blok: SbBlokData & E}> {

}

export enum Dependency {
    SKIP_USER_COUNTRY = "skip_user_country",
    THEME_COLOR = "theme_color",
    MEMBER = "member",
    HEADER_NOTIFICATION = "header_notification",
    CLUB = "club",
    INSTRUCTOR = "instructor",
    CAROUSEL_BANNER = "carousel_banner",
    TOPICS = "topics",
    MARKETPLACE = "marketplace",
    LATEST_NEWS = "latest_news",
    
}

export interface WithParentDependencies {
    parentDependencies?: Array<Dependency>
}

export interface WithDependencies {
    dependencies?: Array<Dependency>
}

export interface StoryblokAsset {
    filename: string
}

export interface WithLokalizeKey {
    lokalize_key: string
}
