
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { BlokComponent, WithParentDependencies } from 'services/model/storyblok';

interface StoryblokProps {
  width_perc?: string
  elements: Array<SbBlokData>
}

/**
 * Done
 */
export const HorizontalScrollable: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
  const appBlok = blok as unknown as StoryblokProps
  const style: React.CSSProperties = {
    width: `${blok.width_perc}%`
  };
  
  return (
    <div {...storyblokEditable(blok)} style={style} className={classNames("flex", "flex-nowrap", "gap-4", "overflow-x-auto", "pt-[0.5rem]", "pb-6", "no-scrollbar")}>
        {appBlok.elements.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} parentDependencies={parentDependencies} key={nestedBlok._uid} />
        ))}
    </div>
  );
};
