function replaceKeyInURL(url: string, key: string, value: string) {
	const regExp = new RegExp(`\\{${key}\\}`, "g");
	return url.replace(regExp, value);
}

export function parseURL(url: string, args: Record<string, any>) {
	let parsedURL = url;
	for (const key in args) {
		if (args.hasOwnProperty(key)) {
			const value = args[key];
			parsedURL = replaceKeyInURL(parsedURL, key, value);
		}
	}
	return parsedURL.replace(/&[\w]+={[\w-]+}/gi, "");
}