import { SbBlokData, SbBlokKeyDataTypes } from "@storyblok/js";
import { StoryblokAsset } from "./model/storyblok";

export const blokDataToString = (blokData: SbBlokKeyDataTypes) => {
    return blokData as string|undefined
}

export const blokDataToArray = (blok: SbBlokData, key: string) => {
    return ((blok?.[key] as unknown as Array<unknown>)?.length ? blok?.[key] : []) as Array<SbBlokData>
}

export const getAssetUrl = (blok: any, key = "background_image") => {
    const image = blok[key] as StoryblokAsset

    return image?.filename
}