import React, { useContext, useState } from "react";
import { useStore } from "services/hooks/useStore";
import { Component } from "services/model/global";
import { ColorModeStore } from "services/store";

////////////////////////////////////////
// Internal Types & Interfaces
////////////////////////////////////////
interface ThemeContextState {
    theme?: Record<COLOR_KEYS, string | undefined>
    setTheme: (t: Record<COLOR_KEYS, string>) => void
    getColor: (t?: COLOR) => string
    setMode: (m: COLOR_MODE) => void
}

////////////////////////////////////////
// Exported Types & Interfaces
////////////////////////////////////////
export interface WithThemeContext {
    ThemeContext: ThemeContextState;
}

////////////////////////////////////////
// Context with default state
////////////////////////////////////////
export enum COLOR_KEYS {
    LIGHT_PRIMARY = "light_primary",
    LIGHT_SECONDARY = "light_secondary",
    LIGHT_TERTIARY = "light_tertiary",
    LIGHT_PRIMARY_VARIANT = "light_primary_variant",
    LIGHT_SECONDARY_VARIANT = "light_secondary_variant",
    LIGHT_TERTIARY_VARIANT = "light_tertiary_variant",
    DARK_PRIMARY = "dark_primary",
    DARK_SECONDARY = "dark_secondary",
    DARK_TERTIARY = "dark_tertiary",
    DARK_PRIMARY_VARIANT = "dark_primary_variant",
    DARK_SECONDARY_VARIANT = "dark_secondary_variant",
    DARK_TERTIARY_VARIANT = "dark_tertiary_variant",
}
export enum COLOR {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    TERTIARY = "tertiary",
    PRIMARY_VARIANT = "primary_variant",
    SECONDARY_VARIANT = "secondary_variant",
    TERTIARY_VARIANT = "tertiary_variant",
}
export enum COLOR_MODE {
    LIGHT = "light",
    DARK = "dark"
}

export interface ColorMode {
    theme: COLOR_MODE
}
// @ts-ignore
export const ThemeContext = React.createContext<ThemeContextState>();

export const ThemeContextProvider: Component<{}> = ({
    children,
}) => {
    const colorMode = useStore(ColorModeStore)
    const [theme, setTheme] = useState<Record<COLOR_KEYS, string | undefined>>();
    const [mode, setMode] = useState<COLOR_MODE | undefined>(colorMode?.theme)

    
    

    const getColor = (type?: COLOR) => {
        if(!mode || !theme){
            return ""
        }
        if (!type) {
            return theme?.[`${mode}_primary`] ?? ''
        }
        return theme?.[`${mode}_${type}`] ?? ''
    }

    return (
        <ThemeContext.Provider value={{ theme, setTheme, getColor, setMode }}>
            {children}
        </ThemeContext.Provider>
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Context Provider & HOC
// Provider to include as the Parent before being able to use this context consumer
// High Order Component, callable at any place in the Component tree under the <..ContextProvider
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// This is a bit redundant as we could call useContext everywhere directly, but jest unit test
// requires this hook to be able to mock it.
export const useThemeContext = () => useContext(ThemeContext);