import { SbBlokData, storyblokEditable } from "@storyblok/js"
import { StoryblokComponent } from "@storyblok/react"
import { useRef, useEffect, useMemo, useState } from "react"
import { useStore } from "services/hooks/useStore"
import { BlokComponent } from "services/model/storyblok"
import { LatestNewsStore, MarketplaceStore } from "services/store"
import { Tab_key } from "./PersonalisationTab"

interface StoryblokProps {

    tabs: Array<SbBlokData>
}

export const PersonalisationSection: BlokComponent<{}, StoryblokProps> = ({ blok }) => {

    const marketplaceItems = useStore(MarketplaceStore)
    const latestNewsItems = useStore(LatestNewsStore)
    const [selectedTab, setSelectedTab] = useState("for_you")
    const [slideIndex, setSlideIndex] = useState(0)
    
   

    const items = useMemo(() => {
        if (selectedTab === "for_you") {
            return marketplaceItems
        }
        if (selectedTab === "event") {
            return latestNewsItems
        }
        return latestNewsItems
    }, [selectedTab, marketplaceItems, latestNewsItems])

    useEffect(() => {
        let slider = setInterval(() => {
            const newIndex = slideIndex + 1 > 2 ? 0 : slideIndex + 1
            setSlideIndex(newIndex)
        }, 3000);
        return () => clearInterval(slider)
    }, [slideIndex])

    const onToucheStartPosition = useRef(0)

    const onTouchStart = (e: React.TouchEvent) => {
        onToucheStartPosition.current = e.changedTouches[0].clientX
    }

    const onTouchEnd = (e: React.TouchEvent) => {
        if (onToucheStartPosition.current < e.changedTouches[0].clientX) {
            const newIndex = slideIndex - 1 < 0 ? 2 : slideIndex - 1
            setSlideIndex(newIndex)
        } else {
            const newIndex = slideIndex + 1 > 2 ? 0 : slideIndex + 1
            setSlideIndex(newIndex)
        }
    }

    const handleOnClick = (key: Tab_key) => {
        setSelectedTab(key)
    }

    return <>
        <div
            {...storyblokEditable(blok)}
            className="w-5/6 justify-center m-auto">
            <div className="flex w-5/6 m-auto  mb-1.5 " >
                {blok.tabs.map((blok) => (
                    <StoryblokComponent
                        onClick={() => handleOnClick(blok.key as Tab_key)}
                        selectedTab={selectedTab}
                        blok={blok} key={blok._uid} />
                ))}
            </div>
            <div
                className="flex w-5/6 h-[150px] m-auto relative overflow-hidden transition-all "
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
            >
                {marketplaceItems?.map((item, itemIndex) => {
                    
                    
                    let positon = "translate-x-full"
                    if (itemIndex === slideIndex) {
                        positon = "translate-x-0"
                    }
                    if (itemIndex === slideIndex - 1 || (slideIndex === 0 && itemIndex === marketplaceItems.length - 1)) {
                        positon = "-translate-x-full"
                    }
                    return (
                        <div
                            className={`flex flex-col justify-end  w-full h-full bg-slate-800 rounded-lg text-slate-50 p-[20px] mr-4 absolute top-0 left-0 trantsition-all ${positon}`}
                            key={item.title}
                        >
                            <p>{item.title}</p>
                            <div className="absolute bottom-[5px] left-0 right-0">
                                <div className="flex items-center justify-center gap-1">
                                    {items?.map((_, dotIndex) => (
                                        <div
                                            key={dotIndex}
                                            className={`trantsition-all w-2 h-2 rounded-full ${slideIndex === dotIndex ? "bg-[#3AAA36]" : "bg-white"}`}>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </>
}