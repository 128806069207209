import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import { BlokComponent } from "services/model/storyblok";

export const GofitMenuMember: BlokComponent = ({ blok }) => {
    return (
        <section
            {...storyblokEditable(blok)}
            className={classNames("flex", "flex-col", "justify-center", "items-center", "gap-4")}
        >
            <div
                className={classNames(
                    "font-bold",
                    "text-3xl",
                    "rounded-[100%]",
                    "shadow-[0px_0px_4px_4px_rgba(255,255,255,1)]",
                    "flex",
                    "items-center",
                    "justify-center",
                    "p-4"
                )}
                style={{ width: "55px", height: "55px" }}
            >
                <span>MD</span>
            </div>
            <div className={classNames("font-bold", "text-xl")}>Hello, Marie!</div>
        </section>
    )
}