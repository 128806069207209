import React, { useCallback, useContext, useEffect, useState } from "react";
import { Language as LokalizeLanguage, LocalizeContextProps, withLocalize } from "react-localize-redux";
import { useStore } from "services/hooks/useStore";
import { Component } from "services/model/global";
import {  TRANSLATION_FILE_CODE } from "services/model/language";
import { LanguageStore } from "services/store";
import { fetchLanguages, fetchTranslationByCode, initializeTranslationOptions, mapApiToTranslationFileLanguage } from "services/translations";
import { enhancedTranslateHelper } from "services/utils/translationHelper";

////////////////////////////////////////
// Internal Types & Interfaces
////////////////////////////////////////
interface TranslationsContextState {
    translate: (value: string, data?: any, options?: any) => string
    activeLanguage: LokalizeLanguage,
    languageIsReady: boolean
}

////////////////////////////////////////
// Exported Types & Interfaces
////////////////////////////////////////
export interface WithTranslationsContext {
    translationsContext: TranslationsContextState;
}

////////////////////////////////////////
// Context with default state
////////////////////////////////////////

// @ts-ignore
export const TranslationsContext = React.createContext<TranslationsContextState>();

export const TranslationsContextProviderWithLocalize: Component<LocalizeContextProps> = ({
    children,
    // Localize functions & props from HOC
    translate: localizeTranslate,
    addTranslationForLanguage,
    initialize,
    setActiveLanguage,
    activeLanguage
}) => {
    const language = useStore(LanguageStore);
    const [languageIsReady, setLanguageIsReady] = useState(false)
    const translate = useCallback(
		(value: string, data?: any, options?: any) => {
			return enhancedTranslateHelper(localizeTranslate, value, data, options) as string;
		},
		[localizeTranslate]
	);

    useEffect(() => {
        const fetchLanguagesAndInitCurrent = async () => {
            
            const languages = await fetchLanguages()
            
            // [{ code: API_LANGUAGE_CODE.ENGLISH, deleted_at: null }]
            
            
            const enableLanguages = languages.filter(l => !Boolean(l.deleted_at)).map(l => l.code);
            const enabledTranslationsFiles = mapApiToTranslationFileLanguage(enableLanguages)

            
            initializeTranslationOptions(initialize, enabledTranslationsFiles);

            const translationFileCode = enabledTranslationsFiles.find(c => c === language) ?? TRANSLATION_FILE_CODE.ENGLISH
            const translation = await fetchTranslationByCode(translationFileCode);

            if (translation) {
                addTranslationForLanguage(translation, translationFileCode);
            }
            setActiveLanguage(translationFileCode);
            setTimeout(() => {
                setLanguageIsReady(true)
            }, 100);
        }
        
        fetchLanguagesAndInitCurrent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    return (
        <TranslationsContext.Provider
            value={{
                // languages,
                activeLanguage,
                languageIsReady,
                translate,
            }}
        >
            {children}
        </TranslationsContext.Provider>
    );
};

export const TranslationsContextProvider = withLocalize(
	TranslationsContextProviderWithLocalize
);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Context Provider & HOC
// Provider to include as the Parent before being able to use this context consumer
// High Order Component, callable at any place in the Component tree under the <..ContextProvider
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// This is a bit redundant as we could call useContext everywhere directly, but jest unit test
// requires this hook to be able to mock it.
export const useTranslationsContext = () => useContext(TranslationsContext);
