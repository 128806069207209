import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useEffect, useState } from "react";
import { getAssetUrl } from "services/blockData";
import { useStore } from "services/hooks/useStore";
import { BlokComponent, StoryblokAsset, WithParentDependencies } from "services/model/storyblok";
import { MemberStore, SkipUserCountryStore } from "services/store";
import { MemberHeader } from "./memberHeader";
import { NonMemberHeader } from "./nonMemberHeader";



interface StoryblokProps {
  default_profile_picture?: StoryblokAsset
  level_icon?: StoryblokAsset
  font: string
  header_notification_background_colour: COLOR

}

/**
* DONE
*/
export const Header: BlokComponent<WithParentDependencies> = ({ blok }) => {
  const appBlok = blok as unknown as StoryblokProps
  const { getColor } = useThemeContext()

  const defaultProfilePicture = getAssetUrl(
    appBlok,
    "default_profile_picture"
  );
  const headerNotificationBackgroundColor = getColor(appBlok.header_notification_background_colour)
  const levelIcon = getAssetUrl(appBlok, "level_icon");
  const member = useStore(MemberStore);
  const skipUserCountry = useStore(SkipUserCountryStore);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (member || skipUserCountry) {
      setLoading(false);
    }
  }, [member, skipUserCountry]);

  if (loading) {
    return (
      <header {...storyblokEditable(blok)} className={classNames("px-4", "h-[160px]")}></header>
    )
  }

  return (
    <header {...storyblokEditable(blok)} className={classNames("px-4")}>
      {member ? ((member?.membership && member?.membership?.country_code === member?.country_code) ?
      (
        <MemberHeader levelIcon={levelIcon} defaultProfilePicture={defaultProfilePicture} headerNotificationsBackgroundColor={headerNotificationBackgroundColor} font={appBlok.font} blok={blok} />
      ) : (
        <NonMemberHeader country={member?.country_code} blok={blok}/>
      )) : (
        <NonMemberHeader  blok={blok}/>
      )
      }
    </header>
  );
};
