import { LatestNewsStore, MarketplaceStore, _AppSubject } from "../store";
import { useState, useEffect} from 'react'
import { Dependency } from "services/model/storyblok";

export const useStore = <T>(store: _AppSubject<T>) => {
    const [data, setData] = useState<T>()

    useEffect(() => {
        store.get(setData)
    }, [store, data])

    return data
}

export const useStoreByDependency = (dependency?: Dependency) => {
    const latestNewsItems = useStore(LatestNewsStore)
    const marketplaceItems = useStore(MarketplaceStore)

    switch(dependency) {
        case Dependency.MARKETPLACE:
            return marketplaceItems;
        case Dependency.LATEST_NEWS:
            return latestNewsItems;
    }
}