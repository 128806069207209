import { MemberTier } from "services/model/member";

export const member_tier_mock: MemberTier = {
    id: 0,
    tier_id: 0,
    user_id: 0,
    tier: {
        id: 0,
        swipe_down: 0,
        order: 0,
        name: "LVL 1",
        swipe_up: 0
    },
    rawProgress: 0,
    progress: 60
}