import { storyblokEditable } from "@storyblok/js";
import { useTranslationsContext } from "contexts/translations";
import { useState } from "react";
import { getAssetUrl } from "services/blockData";
import { BlokComponent, StoryblokAsset, WithLokalizeKey } from "services/model/storyblok"
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";

interface StoryblokProps extends WithLokalizeKey {
    search_icon?: StoryblokAsset  
}

export const SearchbarInput: BlokComponent = ({ blok }) => {

    const appBlok = blok as unknown as StoryblokProps

    const searchIcon = getAssetUrl(appBlok, "search_icon");

    const { translate } = useTranslationsContext();

    const [searchQuery, setSearchQuery] = useState("")

    function handleSubmit() {
        WebviewBridge.post(EVENT_KEY.HOME_VIEW_SEARCH, searchQuery)
    }

    return<>
    <div
    {...storyblokEditable(blok)} 
    className=" w-full  flex items-center pt-[18px] px-[16px] pb-[19px] ">
    
    <form className="w-full bg-white shadow-[0_3px_8px_-0_rgba(0,0,0,0.1)] h-[40px] rounded flex" onSubmit={handleSubmit}>
    <img src={`${searchIcon}`} alt="level_icon" width="20px" height="40px" className="text-slate-800 ml-[12px] mr-[9px]" />
    <input placeholder={translate(appBlok.lokalize_key)} className= "outline-none flex-auto" onChange={(e) => {setSearchQuery(e.target.value)}}/>
    </form>
    
    </div>
    

    </>
}