import { COUNTRY_CODE } from "services/model/country";
import { Member } from "services/model/member";

export const member_mock: Member = {
    // NONMEMBER
    // first_name: "",
    // last_name: "",
    // id: 0,
    // phone: "",
    // uid: "",
    // preferences: {
    //     marketing_from_brand: false,
    //     marketing_third_party: false
    // },
    // country_code: COUNTRY_CODE.MALAYSIA,
    // verified: false,
    // emergency_phone: "",
    // measurement_unit: ""

    // MEMBER
    first_name: "John",
    last_name: "Smith",
    avatar: "https://ewsuat03fs01.blob.core.windows.net/media/profiles/users/20230201235756-287145.jpg",
    membership: {
        id: 1,
        member_first_id: 1,
        member_id: 1,
        club_id: 1,
        country_code: COUNTRY_CODE.MALAYSIA,
        member_status: "Ok"
    },
    id: 0,
    phone: "",
    uid: "",
    preferences: {
        marketing_from_brand: false,
        marketing_third_party: false
    },
    country_code: COUNTRY_CODE.MALAYSIA,
    verified: false,
    emergency_phone: "",
    measurement_unit: ""
}