import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useTranslationsContext } from "contexts/translations";
import { useStore } from "services/hooks/useStore";
import { BlokComponent, WithLokalizeKey } from "services/model/storyblok";
import { InstructorStore } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";
import NavigateNext from "styles/icons/navigate_next_black_24dp.svg"
import defaultImage from "styles/images/group13@3x.png"

interface StoryblokProps extends WithLokalizeKey {
  text_color?: string
  background_color?: string
  font: string
}

export const NextClassBy: BlokComponent = ({ blok }) => {
  const appBlok = blok as unknown as StoryblokProps

  const instructor = useStore(InstructorStore);
  const {translate} = useTranslationsContext()

  const onChooseInstructorClick = () => {
    WebviewBridge.post(EVENT_KEY.VIEW_NEXT_CLASS_BY);
  };

  const buttonClassName = classNames(
    "shadow-[0_3px_8px_-0_rgba(0,0,0,0.24)]",
    "rounded-lg",
    "flex",
    "items-center",
    "p-4",
    "h-full"
  );

  const { getColor } = useThemeContext();

  const style: React.CSSProperties = {};

  if (appBlok.background_color) {
    style.backgroundColor = getColor(appBlok.background_color as COLOR);
  }

  if (appBlok.text_color) {
    style.color = getColor(appBlok.text_color as COLOR);
  }

  return (
    <div
      {...storyblokEditable(blok)}
      onClick={onChooseInstructorClick}
      className={buttonClassName}
      style={style}
    >
      <div className={`uppercase mr-4 ${appBlok.font}`}>{translate(appBlok.lokalize_key)}</div>
      <div className={` w-6 h-6 mr-4 rounded-full bg-cover bg-no-repeat bg-top bg-[url(${instructor?.image ? instructor?.image: defaultImage})]`}>
      </div>
      {instructor ? (
        <div className="text-lg grow">
        {instructor.name.length > 15 ? (
        <span>{instructor.name.substring(0, 15)}...</span>
      ) : (
        <span>{instructor.name}</span>
      )}
        </div>
      ) : (
        <div className="text-lg grow text-[#B2B2B2]">{translate("choose_an_instructor")}</div>
      )}
      <img src={NavigateNext} alt="" />
    </div>
  );
};
