import _isString from "lodash/isString";
// constants
// utilities
import { TranslateFunction } from "react-localize-redux";

// =============================================================================
// General
// =============================================================================
const TRANSLATION_KEY_PATTERN = new RegExp("[\\w-]+");
function isTranslationKey(text: string) {
	return _isString(text) && TRANSLATION_KEY_PATTERN.test(text);
}

// -----------------------------------------------------------------------------
// functions that require translate
// -----------------------------------------------------------------------------
export function enhancedTranslateHelper(translate: TranslateFunction, value: string, ...args: Array<any>) {
    try {
	    return isTranslationKey(value) ? translate(value, ...args) : value;
    } catch (e) {
        return value
    }
}

// Translate the string alongside its data.
// It functions the same as the original translate.
// The only different is that it also translates the value in data and supports plurality.
// enhancedTranslateHelper is used so jsx can be passed as a data value safely.
// Even though it called deepTranslate, it only translate the first layer of arguments instead of recursively.
// It can be used as normal translate as well.
// Docs for original translate: https://ryandrewjohnson.github.io/react-localize-redux-docs/#translate-2
// export function deepTranslateHelper(translate, string, data, ...args) {
// 	let str = string;
// 	if (data) {
// 		let newData = {};
// 		const dataStrings = [];
// 		for (const key in data) {
// 			if (data.hasOwnProperty(key)) {
// 				const value = data[key];
// 				const newValue = enhancedTranslateHelper(translate, value);
// 				// get the new data
// 				newData = { ...newData, [key]: newValue };
// 				if (_isString(newValue) || _isNumber(newValue)) {
// 					// store the date keys
// 					dataStrings.push({
// 						key,
// 						value: newValue
// 					});
// 				}
// 			}
// 		}
// 		const translatedItem = enhancedTranslateHelper(
// 			translate,
// 			str,
// 			newData,
// 			...args
// 		);
// 		const itemToBeReturn = deepStringModification(translatedItem, target =>
// 			parseCustomTranslationGroup(target, dataStrings)
// 		);
// 		return itemToBeReturn;
// 	} else {
// 		return enhancedTranslateHelper(translate, str);
// 	}
// }

// =============================================================================
// Global functions
// =============================================================================
// These functions should be called directly or indirectly by React components

// a function that only accept value but not args
// useful in callback
// FIXME #9962
// export function simpleTranslate(value) {
// 	return enhancedTranslate(value);
// }
// // FIXME #9962
// export function enhancedTranslate(...args) {
// 	return enhancedTranslateHelper(_G.translate, ...args);
// }
// // FIXME #9962
// export function deepTranslate(...args) {
// 	return deepTranslateHelper(_G.translate, ...args);
// }

// function parseCustomTranslationGroup(string, dataStrings) {
// 	if (!_isString(string)) {
// 		return string;
// 	}
// 	let str = string;
// 	dataStrings.forEach(({ key, value }) => {
// 		str = parseCustomTranslation(str, key, value);
// 	});
// 	return str;
// }

// function parseCustomTranslation(string, key, value) {
// 	let parsedStr = string;
// 	const singularityRegExp = new RegExp(
// 		`\\{\\{${key}([<>!]?=?)(\\d*)\\?([^{}:]*):([^{}:]*)\\}\\}`,
// 		"g"
// 	);
// 	parsedStr = parsedStr.replace(
// 		singularityRegExp,
// 		(match, operator, limit, former, later) => {
// 			const numLimit = limit ? Number(limit) : 1;
// 			if (isNaN(numLimit)) return match;
// 			const isFormer = mathCompare(value, numLimit, operator || "=");
// 			return isFormer ? former : later;
// 		}
// 	);
// 	return parsedStr;
// }

