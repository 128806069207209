import { HeaderNotification } from "services/model/headerNotification";

export const headerNotification_mock: Array<HeaderNotification> = [
    { title: "Your suspension starts tomorrow",
    type: "membership_issue", 
    canBeDismissed: false,
    hasMessageBeenDismissed: false },
    { title: "Your QR code relocated to the activity tab",
    type: "qr_relocation",  
    canBeDismissed: true,
    hasMessageBeenDismissed: false  },
    { title: "Please complete your experience survey",
    type: "experience_survey",  
    canBeDismissed: true,
    hasMessageBeenDismissed: false  }
]