import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import { getAssetUrl } from "services/blockData";
import { BlokComponent } from "services/model/storyblok";

interface StoryblokProps {
    icon?: Array<string>
}

export const GofitMenuNotifications: BlokComponent = ({ blok }) => {
    const appBlok = blok as unknown as StoryblokProps
    const notificationIcon = getAssetUrl(appBlok, "icon")
    return (
        <section
            {...storyblokEditable(blok)}
            className={classNames("flex", "flex-col", "justify-center", "items-center", "gap-4")}
        >
            <div
                className={classNames(
                    "font-bold",
                    "text-3xl",
                    "flex",
                    "flex-col",
                    "items-center",
                    "justify-center",
                )}
            >
                <img src={notificationIcon} alt="notif_ico" width="30px" />
                <div className={classNames("font-bold", "text-xl")}>No New Notifications</div>
            </div>
        </section>
    )
}