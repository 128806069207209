import { apiPlugin, storyblokInit } from '@storyblok/react';
import { NextClassButton } from 'components/shared/next-classes/NextClassButton';
import { NextClassBy } from 'components/shared/next-classes/NextClassBy';
import { NextClassIn } from 'components/shared/next-classes/NextClassIn';
import { SearchbarInput } from 'components/shared/next-classes/SearchbarInput';
import { PersonalisationSection } from 'components/fitness-first/personalisation/PersonalisationSection';
import { PersonalisationTab } from 'components/fitness-first/personalisation/PersonalisationTab';
import { GofitCard } from 'components/gofit/card';
import { GofitCardContent } from 'components/gofit/card-content';
import { GofitHeader } from 'components/gofit/header';
import { GofitMenu } from 'components/gofit/menu';
import { GofitMenuMember } from 'components/gofit/menu/GofitMenuMember';
import { GofitMenuNavigation } from 'components/gofit/menu/GofitMenuNavigation';
import { GofitMenuNavigationItem } from 'components/gofit/menu/GofitMenuNavigationItem';
import { GofitMenuNotifications } from 'components/gofit/menu/GofitMenuNotifications';
import { BrandTheme } from 'components/shared/BrandTheme';
import { Card } from 'components/shared/Card';
import { DynamicCard } from 'components/shared/Card/DynamicCard';
import { CarouselBannerComponent } from 'components/shared/CarouselBannerComponent/CarouselBannerComponent';
import { Grid } from 'components/shared/Grid';
import { HorizontalScrollable } from 'components/shared/scrollable/horizontal';
import { CommonSection } from 'components/shared/Section';
import { Title } from 'components/shared/Title';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Tile } from './components/shared/tile';
import { Header } from './components/shared/header';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
import { BannerCard } from 'components/shared/CarouselBannerComponent/BannerCard';
import { LatestNewsCard } from 'components/shared/LatestNewsCard/LatestNewsCard';
import { MarketPlaceCard } from 'components/shared/MarketPlaceCard/MarketPlaceCard';
import { ClassCard } from 'components/shared/ClassCard/ClassCard';
import { CarouselBannerSection } from 'components/shared/CarouselBannerComponent/CarouselBannerSection';
import { TestNewBlock } from 'components/shared/TestNewBlock';

storyblokInit({
  accessToken: 'BDcuiRwlPk9VLDz0rE4Mmgtt',
  apiOptions: {
    region: 'us',
  },
  use: [apiPlugin],
  components: {
    //
    // Common Components
    //
    brand_theme: BrandTheme,
    section: CommonSection,
    title: Title,
    grid: Grid,
    card: Card,
    dynamic_card: DynamicCard,
    horizontal_scrollable: HorizontalScrollable,
    header: Header,
    next_class_in: NextClassIn,
    next_class_by: NextClassBy,
    tile: Tile,
    next_class_button: NextClassButton,
    carousel_banner: CarouselBannerComponent,
    banner_card: BannerCard,
    carousel_banner_section: CarouselBannerSection,
    latest_news_card: LatestNewsCard,
    marketplace_card: MarketPlaceCard,
    class_card: ClassCard,
    test_new_block: TestNewBlock,
    //
    // Fitness First Specific Components
    //
    fitness_first_searchbar: SearchbarInput,
    fitness_first_personalisation_section: PersonalisationSection,
    fitness_first_personalisation_tab: PersonalisationTab,
    //
    // Gofit
    //
    gofit_card_content: GofitCardContent,
    gofit_card: GofitCard,
    gofit_header: GofitHeader,
    gofit_menu: GofitMenu,
    gofit_menu_member: GofitMenuMember,
    gofit_menu_notifications: GofitMenuNotifications,
    gofit_menu_navigation: GofitMenuNavigation,
    gofit_menu_navigation_item: GofitMenuNavigationItem
  },
});
const container = document.getElementById('root');

const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

