import { useTranslationsContext } from "contexts/translations";
import { FaAngleRight, FaExclamationCircle } from "react-icons/fa";
import { getCountryByCode } from "services/country";
import { useStore } from "services/hooks/useStore";
import { COUNTRY_CODE} from "services/model/country";
import { BlokComponent } from "services/model/storyblok";
import { SkipUserCountryStore } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";

interface InputProps {
    country?: COUNTRY_CODE
}

export const NonMemberHeader: BlokComponent<InputProps> = ({ blok, country }) => {

    const { translate } = useTranslationsContext();
    const skipUserCountry = useStore(SkipUserCountryStore);

    const onNonMemberHeaderClick = () => {
        WebviewBridge.post(EVENT_KEY.VIEW_NONMEMBER_HEADER_INFORMATION);
    };

    const countryName = getCountryByCode(country)
    const skipUserCountryName = getCountryByCode(skipUserCountry)
    const countryLabel = country ? countryName : skipUserCountryName
    const countryLabelTranslated = translate(`${countryLabel?.toLowerCase()}`)

    return (
        <>
            <section
                className="grid grid-cols-1 grid-rows-2 ml-6"
                onClick={onNonMemberHeaderClick}
            >
                <div className="flex items-center w-[96%] justify-between">
                    <div >
                        <h1 className="text-xl fitness-first-medium">{translate(`app_home_header_non_member_title` , {country: countryLabelTranslated})}</h1>
                        <p className="text-lg">{translate("and_unlock_full_experience")}</p>
                    </div>
                    <FaAngleRight className="w-[16px] h-[16px] text-slate-400 text-white " />
                </div>
                <div className="flex mt-[16px]">
                    <FaExclamationCircle className="w-[16px] h-[16px] text-slate-400 text-white " />
                    <p className="ml-2 text-base">{translate("non_member_help_info")}</p>
                </div>

            </section>
        </>
    )

}