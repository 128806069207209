import classNames from "classnames";
import { useGofitContext } from "contexts/gofit";
import { BlokComponent } from "services/model/storyblok";

export const GofitHeader: BlokComponent = ({ blok }) => {
    const {setMenuOpen} = useGofitContext();

    return (
        <header
            className={classNames("flex", "justify-between", "p-2")}
            style={{ backgroundColor: `${blok.background_color}`}}
        >
            <img src={`${blok.menu_icon}`} alt="menu_icon" width="30px" onClick={() => setMenuOpen(true)}/>
            <img src={`${blok.title_icon}`} alt="title_icon" width="75px" />
            <img src={`${blok.card_icon}`} alt="card_icon" width="50px" />
        </header>
    )
}