import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useTranslationsContext } from "contexts/translations";
import { BlokComponent, WithLokalizeKey, WithParentDependencies } from "services/model/storyblok";

interface StoryblokProps extends WithLokalizeKey {
    text_color?: COLOR
    label?: string
    font: string
}

/**
 * DONE
 */
export const Title: BlokComponent<WithParentDependencies> = ({ blok }) => {
    const appBlok = blok as unknown as StoryblokProps
    const {getColor} = useThemeContext()
    const style: React.CSSProperties = {}
    const {translate} = useTranslationsContext()

    let label = appBlok.label

    if (appBlok.lokalize_key) {
        label = translate(appBlok.lokalize_key)
    }

    if (appBlok.text_color) {
        style.color = getColor(appBlok.text_color as COLOR)
    }
    return (
        <h1
            {...storyblokEditable(blok)}
            className={classNames("px-4","text-lg", "mb-2", "uppercase", `${appBlok.font}`)}
            style={style}
        >
            {`${label}`}
        </h1>
    )
}