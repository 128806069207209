/**
 * A Unique Code to identify the country
 */
 export enum COUNTRY_CODE {
	HONG_KONG = "hk",
	INDONESIA = "id",
	MALAYSIA = "my",
	PHILIPPINES = "ph",
	SINGAPORE = "sg",
	THAILAND = "th"
}


