import { COUNTRY_CODE } from "./model/country";

export const getCountryByCode = (code?: COUNTRY_CODE) => {
  switch (code) {
    case 'th':
      return 'Thailand';
    case 'id':
      return 'Indonesia';
    case 'my':
      return 'Malaysia';
    case "ph":
      return "Philippines";
    case "sg":
      return "Singapore";
    default:
      return undefined;
  }
};