import { SbBlokData, storyblokEditable } from "@storyblok/js";
import { StoryblokComponent } from "@storyblok/react";
import classNames from "classnames";
import { ColorMode,  COLOR_MODE,  useThemeContext } from "contexts/theme";
import { useEffect } from "react";
import { useDependencies } from "services/hooks/useDependencies";
import { useStore } from "services/hooks/useStore";
import { BlokComponent, Dependency } from "services/model/storyblok";
import { ColorModeStore } from "services/store";
import { Loading } from "../LoadingPage/Loading";

interface StoryblokProps {
  light_primary: string
  light_primary_variant: string
  light_secondary: string
  light_secondary_variant: string
  light_tertiary: string
  light_tertiary_variant: string
  dark_primary: string
  dark_primary_variant: string
  dark_secondary: string
  dark_secondary_variant: string
  dark_tertiary: string
  dark_tertiary_variant: string
  sections: Array<SbBlokData>
}

/**
* DONE
*/
export const BrandTheme: BlokComponent = ({ blok }) => {
  const appBlok = blok as unknown as StoryblokProps
  useDependencies([Dependency.THEME_COLOR])
  const { setTheme, setMode } = useThemeContext();
  const colorMode = useStore<ColorMode>(ColorModeStore);

  useEffect(() => {
    if (colorMode?.theme !== undefined) {
      setMode(colorMode.theme);
    }
  }, [colorMode, setMode]);


  useEffect(() => {
    setTheme(appBlok);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (colorMode?.theme === undefined) {
    return <Loading />;
  }

  const backgroundColor = colorMode.theme === COLOR_MODE.DARK ? "#121212" : "#F2F2F2"
  return (
    <main
      {...storyblokEditable(blok)}
      className={classNames("h-full", "w-full", "p-0", "m-0", `bg-[${backgroundColor}]` )}
    >
      {appBlok.sections?.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </main>
  );
};
