import { useState, useEffect } from "react";
import { BehaviorSubject, take } from "rxjs";
import { COUNTRY_CODE } from "services/model/country";
import { Topic } from "services/model/topic";
import { parseURL } from "services/utils/urlProcessors";
import { endpoints } from "./endpoint";

export class _ApiTopicSubject<T> {
    subject = new BehaviorSubject<T | undefined>(undefined);
    
    fetchTopicsByCountry  = (countryCode: COUNTRY_CODE) => {
        const parsedUrl = parseURL(endpoints.TOPICS, {
          country_code: countryCode
        });

    fetch(parsedUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(responseData => {
      const transformedData = responseData.data || [];
      this.subject.next(transformedData);
    })
    .catch(error => {
      console.error('Error fetching topics by country:', error);
    })
   
  };
  get = (setState: (props: T | undefined) => void) => {
    this.subject.pipe(take(1)).subscribe(setState);
  };
  
}

export const TopicsSubject = new _ApiTopicSubject<Array<Topic>>()

export const useTopicsApiData = <T>(api: _ApiTopicSubject<T>, countryCode: COUNTRY_CODE) => {
    const [data, setData] = useState<T>()
    
    useEffect(() => {
      const fetchData = () => {
        api.fetchTopicsByCountry(countryCode);
      };
  
      fetchData();
  
      const subscription = api.subject.subscribe(setData);
  
      return () => {
        subscription.unsubscribe();
      };
    }, [api, countryCode]);
  

    return data
}
