import { SbBlokData, storyblokEditable } from "@storyblok/js";
import { StoryblokComponent } from "@storyblok/react";
import { useState } from "react";
import { BlokComponent, WithParentDependencies } from "services/model/storyblok";

interface StoryblokProps {
    elements: Array<SbBlokData>
}

export const CarouselBannerComponent: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
    const appBlok = blok as unknown as StoryblokProps

    const [slideIndex, setSlideIndex] = useState(0)
    const bannerItems = appBlok.elements

    const height = (appBlok.elements.length ?? 0) < 1 ? "h-0" : "h-[170px]"

    if (!bannerItems) {
        return null
    }

    return (
        <>
            {appBlok.elements ? (
                <div
                    className="w-full justify-center px-4">
                    <div
                        {...storyblokEditable(blok)}
                        className={`flex relative overflow-hidden transition-all ${height} `}
                    >
                        {appBlok.elements.map((nestedBlok, index) =>
                            <StoryblokComponent
                                blok={nestedBlok}
                                parentDependencies={parentDependencies}
                                key={nestedBlok._uid}
                                elementsLength={appBlok.elements.length}
                                index={index}
                                slideIndex={slideIndex}
                                setSlideIndex={setSlideIndex}
                                bannerItems={bannerItems}
                            />
                        )}

                        {bannerItems?.length > 1 ? (
                            <div className="absolute bottom-[5px] left-0 right-0">
                                <div className="flex items-center justify-center gap-3">
                                    {bannerItems?.map((_, dotIndex) => (
                                        <div
                                            key={dotIndex}
                                            className={`trantsition-all w-2 h-2 rounded-full bg-[#F2F2F2] ${slideIndex === dotIndex ? "scale-125" : "scale-100"}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </>
    )
}
