import { Dependency } from "services/model/storyblok";
import { setCarouselBannerCallbackName, setClubCallbackName, setColorModeCallbackName, setHeaderNotificationCallbackName, setInstructorCallbackName, setLatestNewsCallbackName, setMarketplaceItemsCallbackName, setMemberCallbackName, setMemberTierCallbackName, setSkipUserCountryCallbackName, setTopicsCallbackName } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { COUNTRY_CODE } from "services/model/country";
import { Location } from "@remix-run/router"
import { club_mock } from "_tests/mocks/club_mock";
import { instructor_mock } from "_tests/mocks/instructor_mock";
import { latest_news_mock } from "_tests/mocks/latest_news_mock";
import { marketplace_mock } from "_tests/mocks/marketplace_mock";
import { member_mock } from "_tests/mocks/member_mock";
import { member_tier_mock } from "_tests/mocks/member_tier_mock";
import { topics_mock } from "_tests/mocks/topics_mock";
import { headerNotification_mock } from "_tests/mocks/headerNotification_mock";
import { carouselBanner_mock } from "_tests/mocks/carouselBanner_mock";
import { skip_user_country_mock } from "_tests/mocks/skip_user_country_mock";
import { colorMode_mock } from "_tests/mocks/colorMode_mock";

const mockStore = (location: Location) => {
    const pathnames = location.pathname?.split('/')?.filter((t) => t !== '' && t !== "#") ?? [];
    if (pathnames.includes("demo")) {
        const countries = Object.values(COUNTRY_CODE).map(v => v as string)
        const country = (countries.find(c => pathnames.includes(c)) as COUNTRY_CODE) ?? COUNTRY_CODE.INDONESIA
        window.setSkipUserCountry(skip_user_country_mock)
        window.setClub(club_mock)
        window.setInstructor(instructor_mock)
        window.setLatestNews(latest_news_mock)
        window.setMarketplaceItems(marketplace_mock)
        window.setMember(member_mock)
        window.setMemberTier(member_tier_mock)
        window.setTopics(topics_mock[country])
        window.setHeaderNotification(headerNotification_mock)
        window.setCarouselBanner(carouselBanner_mock)
        window.setColorMode(colorMode_mock)
    }
}

export const useDependencies = (dependencies?: Array<Dependency>) => {
    const location = useLocation()

    useEffect(() => {
        if (dependencies?.length) {
            mockStore(location)
            dependencies.forEach((dependency) => {
                switch (dependency) {
                    case Dependency.SKIP_USER_COUNTRY:
                        WebviewBridge.post(EVENT_KEY.FETCH_SKIP_USER_COUNTRY, { callback: setSkipUserCountryCallbackName });
                        break;
                    case Dependency.MEMBER:
                        WebviewBridge.post(EVENT_KEY.FETCH_MEMBER, { callback: setMemberCallbackName });
                        WebviewBridge.post(EVENT_KEY.FETCH_MEMBER_TIER, { callback: setMemberTierCallbackName });
                        break;
                    case Dependency.HEADER_NOTIFICATION:
                        WebviewBridge.post(EVENT_KEY.FETCH_HEADER_NOTIFICATION, { callback: setHeaderNotificationCallbackName });
                        break;
                    case Dependency.INSTRUCTOR:
                        WebviewBridge.post(EVENT_KEY.FETCH_INSTRUCTOR, { callback: setInstructorCallbackName });
                        break;
                    case Dependency.CAROUSEL_BANNER:
                        WebviewBridge.post(EVENT_KEY.FETCH_CAROUSEL_BANNER, { callback: setCarouselBannerCallbackName });
                        break;
                    case Dependency.TOPICS:
                        WebviewBridge.post(EVENT_KEY.FETCH_TOPICS, { callback: setTopicsCallbackName });
                        break;
                    case Dependency.CLUB:
                        WebviewBridge.post(EVENT_KEY.FETCH_CLUB, { callback: setClubCallbackName });
                        break;
                    case Dependency.MARKETPLACE:
                        WebviewBridge.post(EVENT_KEY.FETCH_MARKETPLACE, { callback: setMarketplaceItemsCallbackName });
                        break;
                    case Dependency.LATEST_NEWS:
                        WebviewBridge.post(EVENT_KEY.FETCH_LATEST_NEWS, { callback: setLatestNewsCallbackName });
                        break;
                    case Dependency.THEME_COLOR:
                        WebviewBridge.post(EVENT_KEY.FETCH_COLOR_MODE, { callback: setColorModeCallbackName });
                        break;

                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
}