import { StoryblokComponent } from "@storyblok/react";
import classNames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { blokDataToArray } from "services/blockData";
import { BlokComponent } from "services/model/storyblok";

export const GofitCard: BlokComponent = ({ blok }) => {
    const content = blokDataToArray(blok, "content")[0];

    const { getColor } = useThemeContext();

    return (
        <div
            className={classNames("w-full", "h-full", "flex", "flex-col", "rounded-md")}
            style={{ backgroundColor: getColor(blok.background_color as COLOR)}}
        >
            <h2
                className={classNames("text-center", "p-4", "uppercase", "font-bold", "text-lg", "rounded-md")}
                style={{ backgroundColor: getColor(blok.title_background_color as COLOR)}}
            >
                {`${blok.title_label}`}
            </h2>
            <div className={classNames("flex-auto", "flex", "flex-col", "justify-center", "items-center")}>
                <StoryblokComponent blok={content} />
            </div>
        </div>
    )
}