import { StoryblokComponent } from "@storyblok/react";
import classNames from "classnames";
import { blokDataToArray } from "services/blockData";
import { BlokComponent } from "services/model/storyblok";

export const GofitMenuNavigation: BlokComponent = ({ blok }) => {
    const elements = blokDataToArray(blok, "elements")
    return (
        <nav className={classNames("flex", "flex-col", "h-full", "gap-5")}>
            {elements?.map((element) => (
                <StoryblokComponent blok={element} />
            ))}
        </nav>
    )
}