export enum EVENT_KEY {
  APP_READY = "APP_READY",
  VIEW_LEVEL_INFORMATION = "VIEW_LEVEL_INFORMATION",
  VIEW_MEMBERSHIP_INFORMATION = "VIEW_MEMBERSHIP_INFORMATION",
  VIEW_PERSONAL_DETAILS_INFORMATION = "VIEW_PERSONAL_DETAILS_INFORMATION",
  VIEW_HEADER_NOTIFICATION = "VIEW_HEADER_NOTIFICATION",
  DISMISS_HEADER_NOTIFICATION = "DISMISS_HEADER_NOTIFICATION",
  VIEW_NONMEMBER_HEADER_INFORMATION = "VIEW_NONMEMBER_HEADER_INFORMATION",
  VIEW_NEXT_CLASS_IN = "VIEW_NEXT_CLASS_IN",
  VIEW_NEXT_CLASS_BY = "VIEW_NEXT_CLASS_BY",
  VIEW_CAROUSEL_BANNER = "VIEW_CAROUSEL_BANNER",
  VIEW_CAROUSEL_BANNER_EXTERNAL_LINK = "VIEW_CAROUSEL_BANNER_EXTERNAL_LINK",
  DEBUG = "DEBUG",
  VIEW_TOPIC = "VIEW_TOPIC",
  VIEW_MARKETPLACE_ITEM = "VIEW_MARKETPLACE_ITEM",
  VIEW_LATEST_NEWS = "VIEW_LATEST_NEWS",
  //
  FETCH_SKIP_USER_COUNTRY = "FETCH_SKIP_USER_COUNTRY",
  FETCH_MEMBER = "FETCH_MEMBER",
  FETCH_MEMBER_TIER = "FETCH_MEMBER_TIER",
  FETCH_HEADER_NOTIFICATION = "FETCH_HEADER_NOTIFICATION",
  FETCH_CLUB = "FETCH_CLUB",
  FETCH_INSTRUCTOR = "FETCH_INSTRUCTOR",
  FETCH_CAROUSEL_BANNER = "FETCH_CAROUSEL_BANNER",
  FETCH_TOPICS = "FETCH_TOPICS",
  FETCH_MARKETPLACE = "FETCH_MARKETPLACE",
  FETCH_LATEST_NEWS = "FETCH_LATEST_NEWS",
  FETCH_COLOR_MODE = "FETCH_COLOR_MODE",
  // 
  HOME_VIEW_SEARCH = "HOME_VIEW_SEARCH"
}

type PostMessage = (params?: any) => void;

interface SwiftHandler {
  messageHandlers: {
    webviewEventHandler: { postMessage: PostMessage };
  };
}

enum AndroidKey {
  appReady = "appReady",
  viewLevelInformation = "viewLevelInformation",
  viewMembershipInformation = "viewMembershipInformation",
  viewPersonalDetailsInformation = "viewPersonalDetailsInformation",
  viewHeaderNotification = "viewHeaderNotification",
  dismissHeaderNotification = "dismissHeaderNotification",
  viewNonMemberHeaderInformation = "viewNonMemberHeaderInformation",
  viewNextClassIn = "viewNextClassIn",
  viewNextClassBy = "viewNextClassBy",
  viewCarouselBanner = "viewCarouselBanner",
  viewCarouselBannerExternalLink = "viewCarouselBannerExternalLink",
  debug = "debug",
  viewMarketPlaceItem = "viewTopic",
  viewTopic = "viewTopic",
  viewLatestNewsItem = "viewTopic",
  fetchSkipUserCountry = "fetchSkipUserCountry",
  fetchClub = "fetchClub",
  fetchInstructor = "fetchInstructor",
  fetchLatestNews = "fetchLatestNews",
  fetchMember = "fetchMember",
  fetchMemberTier = "fetchMemberTier",
  fetchHeaderNotification = "fetchHeaderNotification",
  fetchCarouselBanner = "fetchCarouselBanner",
  fetchTopics = "fetchTopics",
  fetchMarketplace = "fetchMarketplace",
  fetchColorMode = "fetchColorMode"
}
interface AndroidHandler {
  appReady: PostMessage
  viewLevelInformation: PostMessage
  viewMembershipInformation: PostMessage
  viewPersonalDetailsInformation: PostMessage
  viewHeaderNotification: PostMessage
  dismissHeaderNotification: PostMessage
  viewNonMemberHeaderInformation: PostMessage
  viewNextClassIn: PostMessage
  viewNextClassBy: PostMessage
  viewCarouselBanner: PostMessage
  viewCarouselBannerExternalLink: PostMessage
  debug: PostMessage
  viewTopic: PostMessage
  viewMarketPlaceItem: PostMessage
  viewLatestNewsItem: PostMessage
  fetchSkipUserCountry: PostMessage
  fetchClub: PostMessage
  fetchInstructor: PostMessage
  fetchLatestNews: PostMessage
  fetchMember: PostMessage
  fetchMemberTier: PostMessage
  fetchHeaderNotification: PostMessage
  fetchCarouselBanner: PostMessage
  fetchTopics: PostMessage
  fetchMarketplace: PostMessage
  fetchColorMode: PostMessage
}

// @ts-ignore
const getSwiftInterface = () => window.webkit as SwiftHandler | undefined;

const getAndroidInterface = (isView?: boolean) => {
  try {
    // @ts-ignore
    return (isView ? EWSNavigationInterface : EWSAndroidInterface) as AndroidHandler;
  } catch (e) {
    return undefined
  }
}

const androidFunctionReady = (key: AndroidKey, isview?: boolean) => {
  const androidInterface = getAndroidInterface(isview)!
  return typeof androidInterface[key] === "function"
}

class _WebviewBridge {
  isSwift: boolean = false;
  isAndroid: boolean = false;

  constructor() {
    this.isSwift = Boolean(getSwiftInterface())
    this.isAndroid = Boolean(getAndroidInterface());
  }

  post = (action: EVENT_KEY, params?: any) => {
    if (this.isSwift) {
      getSwiftInterface()?.messageHandlers.webviewEventHandler.postMessage({
        action,
        params,
      });
    } else if (this.isAndroid) {
      const paramsAsString = JSON.stringify(params);

      switch (action) {
        case EVENT_KEY.APP_READY:
          if (androidFunctionReady(AndroidKey.appReady)) {
            getAndroidInterface()!.appReady();
          }
          break;
        case EVENT_KEY.DEBUG:
          if (androidFunctionReady(AndroidKey.debug)) {
            getAndroidInterface()!.debug(paramsAsString);
          }
          break;
        case EVENT_KEY.VIEW_LEVEL_INFORMATION:
          if (androidFunctionReady(AndroidKey.viewLevelInformation, true)) {
            getAndroidInterface(true)!.viewLevelInformation();
          }
          break;
        case EVENT_KEY.VIEW_MEMBERSHIP_INFORMATION:
          if (androidFunctionReady(AndroidKey.viewMembershipInformation, true)) {
            getAndroidInterface(true)!.viewMembershipInformation();
          }
          break;
        case EVENT_KEY.VIEW_PERSONAL_DETAILS_INFORMATION:
          if (androidFunctionReady(AndroidKey.viewPersonalDetailsInformation, true)) {
            getAndroidInterface(true)!.viewPersonalDetailsInformation();
          }
          break;
        case EVENT_KEY.VIEW_HEADER_NOTIFICATION:
          if (androidFunctionReady(AndroidKey.viewHeaderNotification, true)) {
            getAndroidInterface(true)!.viewHeaderNotification(paramsAsString);
          }
          break;
        case EVENT_KEY.DISMISS_HEADER_NOTIFICATION:
          if (androidFunctionReady(AndroidKey.dismissHeaderNotification, true)) {
            getAndroidInterface(true)!.dismissHeaderNotification(paramsAsString);
          }
          break;
        case EVENT_KEY.VIEW_NONMEMBER_HEADER_INFORMATION:
          if (androidFunctionReady(AndroidKey.viewNonMemberHeaderInformation, true)) {
            getAndroidInterface(true)!.viewNonMemberHeaderInformation();
          }
          break;
        case EVENT_KEY.VIEW_NEXT_CLASS_BY:
          if (androidFunctionReady(AndroidKey.viewNextClassBy, true)) {
            getAndroidInterface(true)!.viewNextClassBy();
          }
          break;
        case EVENT_KEY.VIEW_NEXT_CLASS_IN:
          if (androidFunctionReady(AndroidKey.viewNextClassIn, true)) {
            getAndroidInterface(true)!.viewNextClassIn();
          }
          break;
        case EVENT_KEY.VIEW_CAROUSEL_BANNER:
          if (androidFunctionReady(AndroidKey.viewCarouselBanner, true)) {
            getAndroidInterface(true)!.viewCarouselBanner(paramsAsString);
          }
          break;
          case EVENT_KEY.VIEW_CAROUSEL_BANNER_EXTERNAL_LINK:
            if (androidFunctionReady(AndroidKey.viewCarouselBannerExternalLink, true)) {
              getAndroidInterface(true)!.viewCarouselBannerExternalLink(paramsAsString);
            }
            break;
        case EVENT_KEY.VIEW_TOPIC:
          if (androidFunctionReady(AndroidKey.viewTopic, true)) {
            getAndroidInterface(true)!.viewTopic(paramsAsString);
          }
          break;
        case EVENT_KEY.VIEW_LATEST_NEWS:
          if (androidFunctionReady(AndroidKey.viewLatestNewsItem, true)) {
            getAndroidInterface(true)!.viewLatestNewsItem(paramsAsString);
          }
          break;
        case EVENT_KEY.VIEW_MARKETPLACE_ITEM:
          if (androidFunctionReady(AndroidKey.viewMarketPlaceItem, true)) {
            getAndroidInterface(true)!.viewMarketPlaceItem(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_SKIP_USER_COUNTRY:
          if (androidFunctionReady(AndroidKey.fetchSkipUserCountry)) {
            getAndroidInterface()!.fetchSkipUserCountry(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_CLUB:
          if (androidFunctionReady(AndroidKey.fetchClub)) {
            getAndroidInterface()!.fetchClub(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_INSTRUCTOR:
          if (androidFunctionReady(AndroidKey.fetchInstructor)) {
            getAndroidInterface()!.fetchInstructor(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_LATEST_NEWS:
          if (androidFunctionReady(AndroidKey.fetchLatestNews)) {
            getAndroidInterface()!.fetchLatestNews(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_MEMBER:
          if (androidFunctionReady(AndroidKey.fetchMember)) {
            getAndroidInterface()!.fetchMember(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_MEMBER_TIER:
          if (androidFunctionReady(AndroidKey.fetchMemberTier)) {
            getAndroidInterface()!.fetchMemberTier(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_HEADER_NOTIFICATION:
          if (androidFunctionReady(AndroidKey.fetchHeaderNotification)) {
            getAndroidInterface()!.fetchHeaderNotification(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_CAROUSEL_BANNER:
          if (androidFunctionReady(AndroidKey.fetchCarouselBanner)) {
            getAndroidInterface()!.fetchCarouselBanner(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_TOPICS:
          if (androidFunctionReady(AndroidKey.fetchTopics, true)) {
            getAndroidInterface(true)!.fetchTopics(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_MARKETPLACE:
          if (androidFunctionReady(AndroidKey.fetchMarketplace)) {
            getAndroidInterface()!.fetchMarketplace(paramsAsString);
          }
          break;
        case EVENT_KEY.FETCH_COLOR_MODE:
          if (androidFunctionReady(AndroidKey.fetchColorMode)) {
            getAndroidInterface()!.fetchColorMode(paramsAsString);
          }
          break;
      }
    } else {
      console.warn("No Webview")
    }
  };
}

export const WebviewBridge = new _WebviewBridge();

