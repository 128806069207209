export enum API_LANGUAGE_CODE {
	FRENCH = "FR",
	ENGLISH = "EN",
	THAI = "TH",
	INDONESIAN = "ID",
	// TODO Define better enum names for following languages
	MS = "MS",
	ZH_HANT = "ZH-HANT",
	ZH_HANS = "ZH-HANS",
	PH = "PH",
	sd = "sd",
	ur = "ur",
	te = "te",
	plop7 = "plop7",
	plop6 = "plop6",
	he = "he",
	ap = "ap",
	La = "La",
	as = "as"
}

export enum TRANSLATION_FILE_CODE {
	FRENCH = "fr",
	ENGLISH = "en",
	CHINESE_TRADITIONAL = "zh_TW", // CHINESE TAIWAN
	THAI = "th",
	INDONESIAN = "in",
	MALAY = "ms_MY"
}

export enum NATIVE_LANGUAGE_CODE {
	FRENCH = "fr",
	ENGLISH = "en",
	CHINESE_TRADITIONAL = "zh", // CHINESE TAIWAN
	THAI = "th",
	INDONESIAN_IOS = "id",
	INDONESIAN_ANDROID = "in",
	MALAY = "ms"
}

export enum STORYBLOK_LANGUAGE {
	FRENCH = "fr",
	ENGLISH = "en",
	THAI = "th",
	INDONESIAN = "id",
	MALAY = "ms",
	TAGALOG = "tl",
	CHINESE_TAIWAN = "zh-tw"
}

export interface Language {
	// Language code such as EN / FR
	readonly code: API_LANGUAGE_CODE;
	// Date formatted as yyyy-mm-dd:hh:ssZ
	readonly created_at: string;
	// Date formatted as yyyy-mm-dd:hh:ssZ
	readonly deleted_at?: string;
	// Label in english
	readonly english_name: string;
	// Unique id
	readonly id: number;
	// Label in local language
	readonly name: string;
}