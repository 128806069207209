import { LatestNewsItem } from "services/model/latestNews";

export const latest_news_mock: Array<LatestNewsItem> = [
    { id: 1, title: "New booking rules" }, 
    { id: 2, title: "Check-in process update"}, 
    { id: 3, title: "The QR code relocates" },
    { id: 4, title: "Penalties on no-shows & cancellations" },
    { id: 5, title: "Booking limitation for your safety" },
    { id: 6, title: "All-in-one personal trainer" },
    { id: 7, title: "Prevent the spread of COVID-19" }
]
