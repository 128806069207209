import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react";
import classNames from "classnames";
import { BlokComponent, WithParentDependencies } from "services/model/storyblok";

interface StoryblokProps {
  col_span?: number
  row_span?: number
  alignment: "normal"| "self-start" |"self-end"| "center"
  content: Array<SbBlokData>
}

/**
* NOT IN USE
*/
export const Card: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
  const appBlok = blok as unknown as StoryblokProps
  const content = appBlok.content[0];


  return (
   <>
   {content ? (
     <div
     {...storyblokEditable(blok)}
     className={classNames(appBlok.col_span && `col-span-${appBlok.col_span}`, appBlok.row_span && `row-span-${appBlok.row_span}`, `justify-${appBlok.alignment}`)}
   >
       <StoryblokComponent
         blok={{...content, isNewsItem: blok.isNewsItem, isMarketplaceItem: blok.isMarketplaceItem }}
         parentDependencies={parentDependencies}
       />
   </div>
   ) : null}
   </>
  );
};
