import { SbBlokData, StoryblokComponent } from "@storyblok/react";
import { useStoreByDependency } from "services/hooks/useStore";
import { ArrayOfOne } from "services/model/global";
import { BlokComponent, WithParentDependencies } from "services/model/storyblok";

interface StoryblokProps {
  col_span?: number
  row_span?: number
  content: ArrayOfOne<SbBlokData>
}

/**
* NOT IN USE
*/
export const DynamicCard: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
  const appBlok = blok as unknown as StoryblokProps
  const items = useStoreByDependency(parentDependencies?.[0]);

  const ffcard = appBlok.content[0];

  return (
    <>
      {items?.map((item) => (
        // @ts-ignore
        <StoryblokComponent
          blok={{...ffcard, key: item.title }}
          parentDependencies={parentDependencies}
          key={item.title}
        />
      ))}
    </>
  );
};
