import { StoryblokComponent } from "@storyblok/react";
import classNames from "classnames";
import { useGofitContext } from "contexts/gofit";
import { blokDataToArray } from "services/blockData";
import { useOutsideClick } from "services/hooks/useClickOutside";
import { BlokComponent } from "services/model/storyblok";

export const GofitMenu: BlokComponent = ({ blok }) => {
  const { menuOpen, setMenuOpen } = useGofitContext();
    const ref = useOutsideClick(() => setMenuOpen(false))

  const containerStyle: React.CSSProperties = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "55%",
    zIndex: "100",
    transform: "translateX(0)",
    height: '100%',
    backgroundColor: '#fff'
  };

  containerStyle.width = `${blok.width || '55%'}`;

  if (!menuOpen) {
    containerStyle.transform = "translateX(-100%)"
  }

  const elements = blokDataToArray(blok, "elements");

  let dummyWidth = "45%"

  if (ref?.current) {
    const menuWidth = parseInt(ref.current.style.width.replace("%", ""))
    dummyWidth = `${100 - menuWidth}%`
  }

  return (
    <>
    <section ref={ref} className={classNames("flex", "flex-col")} style={containerStyle}>
      {elements?.length ? (
        elements.map((nestedBlok) => <StoryblokComponent blok={nestedBlok} />)
      ) : (
        <span>No elements</span>
      )}
    </section>
    {menuOpen && <div className="fixed right-0 top-0 bg-slate-700/80 h-full" style={{ width: dummyWidth }}></div>}
    </>
  );
};
