import { BRAND_CODE } from "services/model/brand";
import { Club } from "services/model/club";
import { COUNTRY_CODE } from "services/model/country";

export const club_mock: Club = {
    id: 0,
    clubWebLink: "",
    favorite: false,
    clubOpeningTimes: [],
    display: false,
    clubPhoneNumber: "",
    address: "",
    allow_checkout: false,
    brandCode: BRAND_CODE.FITNESS_FIRST,
    clubWebsiteName: "The Mall Ngamwongwan",
    timezone: "",
    countryCode: COUNTRY_CODE.SINGAPORE,
    name: ""
}