import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react"
import { COLOR, useThemeContext } from "contexts/theme"
import { useDependencies } from "services/hooks/useDependencies"
import { useStore } from "services/hooks/useStore"
import { BlokComponent, WithDependencies } from "services/model/storyblok"
import { MemberStore } from "services/store"

interface StoryblokProps extends WithDependencies {
    background_color?: COLOR
    text_color?: COLOR
    elements: Array<SbBlokData>
}


export const CarouselBannerSection: BlokComponent = ({ blok }) => {
    const appBlok = blok as unknown as StoryblokProps
    const {getColor} = useThemeContext()
    useDependencies(appBlok.dependencies)

    const style: React.CSSProperties = {}
    const member = useStore(MemberStore);
    const padding = (member?.membership 
        && member?.membership?.country_code === member?.country_code
        && member.membership.member_status
        && !["Cancelled", "Deceased", "Banned"].includes(member?.membership?.member_status))  ? "pb-5 pt-[1rem]" : "p-0"

    if (appBlok.background_color) {
        style.backgroundColor = getColor(appBlok.background_color)
    }

    if (appBlok.text_color) {
        style.color = getColor(appBlok.text_color)
    }

    if (member?.membership 
        && member?.membership?.country_code === member?.country_code
        && member.membership.member_status
        && !["Cancelled", "Deceased", "Banned"].includes(member?.membership?.member_status)){
        return (
            <section {...storyblokEditable(blok)} 
            style={style} 
            className={padding}
            >
                {appBlok.elements.map((blok) => (
                    <StoryblokComponent blok={blok} key={blok._uid} parentDependencies={appBlok.dependencies} />
                ))}
            </section>
        )
    }else{
        return null
    }

    
}