export const Loading = () => {

    return (
        <div className={`animate-pulse w-full h-[1000px] bg-[#7F7F7F]`}>
            <div className="flex w-full h-[250px] justify-around bg-[#4D4D4D]">
            <div className="rounded-full h-[90px] w-[90px] my-10 bg-[#333333]"></div>
            </div>
            <div className="w-[90%] h-[50px] bg-[#4D4D4D] rounded-md  mx-auto mt-8"></div>
            <div className="w-[90%] h-[50px] bg-[#4D4D4D] rounded-md  mx-auto mt-3"></div>
            <div className="w-[90%] h-[150px] bg-[#4D4D4D] rounded-md  mx-auto mt-10"></div>
            <div className="w-[120px] h-[15px] bg-[#4D4D4D] rounded  mx-6 mt-14"></div>
            <div className=" w-[90%] h-[160px] bg-[#7F7F7F] rounded-md flex justify-between mx-auto mt-3">
            <div className="w-[46%] h-[150px] bg-[#4D4D4D] rounded  mt-3"></div> 
            <div className="w-[46%] h-[150px] bg-[#4D4D4D] rounded  mt-3"></div>
            </div>
            <div className=" w-[90%] h-[160px] bg-[#7F7F7F] rounded-md flex justify-between mx-auto mt-3">
            <div className="w-[46%] h-[150px] bg-[#4D4D4D] rounded  mt-3"></div> 
            <div className="w-[46%] h-[150px] bg-[#4D4D4D] rounded  mt-3"></div>
            </div>

        </div>

    )
}