import { storyblokEditable } from "@storyblok/js";
import classNames from "classnames";
import classnames from "classnames";
import { COLOR, useThemeContext } from "contexts/theme";
import { useTranslationsContext } from "contexts/translations";
import { useMemo } from "react";
import { useStore } from "services/hooks/useStore";
import { BlokComponent, Dependency, WithParentDependencies } from "services/model/storyblok";
import { LatestNewsStore } from "services/store";
import { EVENT_KEY, WebviewBridge } from "services/webviewBridge";

interface StoryblokProps {
    label: string
    font: string
    key: string
    text_color?: COLOR
    border_color?: COLOR
    background_color?: COLOR
    col_span?: number
    row_span?: number
    alignment: "normal" | "self-start" | "self-end" | "center"
}
type CardItem = {
    eventParam?: string
    eventKey: EVENT_KEY
    displayValue?: string
}

const useLatestNewsCardItem = (key?: string, parentDependencies?: Array<Dependency>): CardItem | undefined => {
    const latestNewsItems = useStore(LatestNewsStore)

    return useMemo(() => {
        if (parentDependencies?.includes(Dependency.LATEST_NEWS)) {
            const latestNewsItem = latestNewsItems?.find(({ id }) => id === parseInt(key ?? ""))
            return {
                eventKey: EVENT_KEY.VIEW_LATEST_NEWS,
                eventParam: latestNewsItem?.title,
                displayValue: latestNewsItem?.title
            }
        }

    }, [key, parentDependencies, latestNewsItems])


}

export const LatestNewsCard: BlokComponent<WithParentDependencies> = ({ blok, parentDependencies }) => {
    const appBlok = blok as unknown as StoryblokProps

    const item = useLatestNewsCardItem(appBlok.key, parentDependencies)
    const { getColor } = useThemeContext();
    const { translate } = useTranslationsContext();

    const textColor = getColor(appBlok.text_color);
    const backgroundColor = getColor(appBlok.background_color);
    const borderColor = getColor(appBlok.border_color);
    

    const className = classnames(
        "rounded-md",
        "uppercase",
        "flex",
        "flex-col",
        "items-start",
        "justify-end",
        "p-3",
        "flex-1",
        "h-full",
        "leading-[13px]",
        "relative",
        `text-[${textColor}]`,
        `bg-[${backgroundColor}]`,
        "shadow-[0_5px_10px_0px_rgba(0,0,0,0.3)]"

    );

    const onClick = () => {
        if (item) {
          WebviewBridge.post(item.eventKey, item.eventParam);
        } else {
          WebviewBridge.post(EVENT_KEY.DEBUG, `NO LATEST NEWS CARD ITEM FOUND FOR  ${appBlok.label}`);
        }
      }
      if (!item?.displayValue) {
        return null
      }

      const latestNewLabel = item?.displayValue?.length > 80 ? item?.displayValue?.substring(0, 40) : item?.displayValue

    return (
        <>
            {item ? (
                <div
                {...storyblokEditable(blok)}
                className={classNames(appBlok.col_span && `col-span-${appBlok.col_span}`, appBlok.row_span && `row-span-${appBlok.row_span}`, `justify-${appBlok.alignment}`, "pr-4")}
              >
                <div
                    {...storyblokEditable(blok)}
                    onClick={onClick}
                    className={className}
                >
                    <span className={`${appBlok.font} mb-1`}>{appBlok.label ? appBlok.label : `${translate(latestNewLabel)}`}</span>
                    <div className={`w-[60px] h-[2px] bg-[${borderColor}] bottom-[6px] left-[6px]`} />
                </div>
                </div>) : null
            }
        </>
    )

}
