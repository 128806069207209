import { storyblokEditable } from "@storyblok/js"
import { useTranslationsContext } from "contexts/translations"
import React from "react"
import { BlokComponent, WithLokalizeKey } from "services/model/storyblok"

export enum Tab_key {
    forYou = "for_you",
    event = "event",
    highlight = "highlight"
}

interface StoryblokProps extends WithLokalizeKey {
    label?: string
    key: Tab_key
}
interface InputProps {
    onClick: React.MouseEventHandler
    selectedTab: Tab_key
}


export const PersonalisationTab: BlokComponent<InputProps, StoryblokProps> = ({blok ,onClick, selectedTab}) => {
    
    const { translate } = useTranslationsContext()
    
    
    let label = blok.label
    let key = blok.key

    if (blok.lokalize_key) {
        label = translate(blok.lokalize_key)
    }
   
    return (
        <div
            {...storyblokEditable(blok)}
            onClick= {onClick}
            key={key}
            className={`w-[250px] h-[25px]  mr-4 ${selectedTab === key && "border-b-4 border-[#3AAA36]"} `}
            >
            <p className="uppercase text-xl font-black" >
            {label}
               
            </p>

        </div>

    )
}
