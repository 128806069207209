import { storyblokEditable } from "@storyblok/js";
import { useTranslationsContext } from "contexts/translations";
import { BlokComponent, WithLokalizeKey } from "services/model/storyblok"
import Bell from "styles/icons/notifications_white_24dp.svg"

interface StoryblokProps extends WithLokalizeKey {

    button_text: string
    placeholder: string
}

export const TestNewBlock: BlokComponent = ({ blok }) => {

    const appBlok = blok as unknown as StoryblokProps

    const { translate } = useTranslationsContext();

    return<>
    <div
    {...storyblokEditable(blok)} 
    className=" w-full  flex items-center pt-[18px] px-[16px] pb-[19px] ">
    
    <form className="w-full bg-white shadow-[0_3px_8px_0_rgba(0,0,0,0.1)] h-[40px] rounded flex">
    <img src={Bell} alt="level_icon" width="20px" height="40px" className="text-slate-800 ml-[12px] mr-[9px]" />
    <input placeholder={translate(appBlok.placeholder)} className= "outline-none flex-auto"/>
    
    </form>
    <button className="h-[40px] w-[60px] bg-[#C8102E] rounded ml-4 text-slate-100 ">{appBlok.button_text}</button>
    </div>
    </>
}