import { InitializePayload, MissingTranslationOptions, SingleLanguageTranslation } from "react-localize-redux";
import { API_LANGUAGE_CODE, Language, NATIVE_LANGUAGE_CODE, STORYBLOK_LANGUAGE, TRANSLATION_FILE_CODE } from "./model/language";
import { renderToStaticMarkup } from "react-dom/server";
import { isString } from "lodash";

export const fetchLanguages = async () => {
    const response = await fetch("https://api-uat03.circuithq.com/mobile/v4/lang", {
        method: "GET"
    });
    let responseJson: { data: Array<Language> } = { data: [] }
    try {
		responseJson = await response.json()
	} catch (error) {
		return responseJson.data
	}
    return responseJson.data
}

export const fetchTranslationByCode = async (code: string) => {
	try {
		const response = await fetch(`/translations/${code}.json`, {
            method: "GET"
        });
		let responseJson: SingleLanguageTranslation = await response.json()
        return responseJson;
    } catch (error) {
        return {}
    }
};

/**
 * Initialize Lokalize Options. To call before initializing translations.
 */
export const initializeTranslationOptions = (
	initialize: (payload: InitializePayload) => void,
	languages?: Array<TRANSLATION_FILE_CODE>
) => {
	if (languages?.length) {
		// initialize
		const initializeOptions = {
			defaultLanguage: TRANSLATION_FILE_CODE.ENGLISH,
			renderToStaticMarkup,
			renderInnerHtml: true,
			onMissingTranslation: (props: MissingTranslationOptions) => {
				if (isString(props.defaultTranslation)) {
					if (
						props.defaultTranslation ===
						"No default translation found! Ensure you've added translations for your default langauge."
					) {
						return props.translationId;
					}
					return props.defaultTranslation;
				} else {
					return renderToStaticMarkup(props.defaultTranslation);
				}
			}
		};
		initialize({
			languages,
			options: initializeOptions
		});
	}
};

export const mapApiToTranslationFileLanguage = (codes: Array<API_LANGUAGE_CODE>) => {
	return codes.map(code => {
		switch (code) {
			case API_LANGUAGE_CODE.ZH_HANS:
			case API_LANGUAGE_CODE.ZH_HANT:
				return TRANSLATION_FILE_CODE.CHINESE_TRADITIONAL;
			case API_LANGUAGE_CODE.ENGLISH:
				return TRANSLATION_FILE_CODE.ENGLISH;
			case API_LANGUAGE_CODE.FRENCH:
				return TRANSLATION_FILE_CODE.FRENCH;
			case API_LANGUAGE_CODE.INDONESIAN:
				return TRANSLATION_FILE_CODE.INDONESIAN;
			case API_LANGUAGE_CODE.THAI:
				return TRANSLATION_FILE_CODE.THAI;
			case API_LANGUAGE_CODE.MS:
				return TRANSLATION_FILE_CODE.MALAY;
			default:
				return TRANSLATION_FILE_CODE.ENGLISH;
		}
	})
}

export const mapNativeToStoryblokLanguage = (code: NATIVE_LANGUAGE_CODE) => {
	switch (code) {
		case NATIVE_LANGUAGE_CODE.CHINESE_TRADITIONAL:
			return STORYBLOK_LANGUAGE.CHINESE_TAIWAN;
		case NATIVE_LANGUAGE_CODE.ENGLISH:
			return STORYBLOK_LANGUAGE.ENGLISH;
		case NATIVE_LANGUAGE_CODE.FRENCH:
			return STORYBLOK_LANGUAGE.FRENCH;
		case NATIVE_LANGUAGE_CODE.INDONESIAN_ANDROID:
		case NATIVE_LANGUAGE_CODE.INDONESIAN_IOS:
			return STORYBLOK_LANGUAGE.INDONESIAN;
		case NATIVE_LANGUAGE_CODE.MALAY:
			return STORYBLOK_LANGUAGE.MALAY;
		case NATIVE_LANGUAGE_CODE.THAI:
			return STORYBLOK_LANGUAGE.THAI;
		default:
			return STORYBLOK_LANGUAGE.ENGLISH;
	}
}

export const mapNativeToTranslationLanguage = (code: NATIVE_LANGUAGE_CODE) => {
	switch (code) {
		case NATIVE_LANGUAGE_CODE.CHINESE_TRADITIONAL:
			return TRANSLATION_FILE_CODE.CHINESE_TRADITIONAL;
		case NATIVE_LANGUAGE_CODE.ENGLISH:
			return TRANSLATION_FILE_CODE.ENGLISH;
		case NATIVE_LANGUAGE_CODE.FRENCH:
			return TRANSLATION_FILE_CODE.FRENCH;
		case NATIVE_LANGUAGE_CODE.INDONESIAN_ANDROID:
		case NATIVE_LANGUAGE_CODE.INDONESIAN_IOS:
			return TRANSLATION_FILE_CODE.INDONESIAN;
		case NATIVE_LANGUAGE_CODE.MALAY:
			return TRANSLATION_FILE_CODE.MALAY;
		case NATIVE_LANGUAGE_CODE.THAI:
			return TRANSLATION_FILE_CODE.THAI;
		default:
			return TRANSLATION_FILE_CODE.ENGLISH;
	}
}