import { COUNTRY_CODE } from "services/model/country";
import { Topic } from "services/model/topic";

export const topics_mock: Record<COUNTRY_CODE, Array<Topic>> = {
    [COUNTRY_CODE.MALAYSIA]: [
        {
            id: 13,
            name: "Gym Floor Access",
            color: "red",
            subTopics: []
        },{
            id: 7,
            name: "Cardio",
            color: "red",
            subTopics: []
        }, {
            id: 11,
            name: "HIIT",
            color: "red",
            subTopics: []
        }, {
            id: 12,
            name: "Feature Class",
            color: "red",
            subTopics: []
        }, {
            id: 47,
            name: "Cycling",
            color: "red",
            subTopics: []
        }, {
            id: 8,
            name: "Strength and Conditioning",
            color: "red",
            subTopics: []
        }, {
            id: 10,
            name: "Mind & Body",
            color: "red",
            subTopics: []
        }, {
            id: 9,
            name: "Dance",
            color: "red",
            subTopics: []
        }
    ],
    [COUNTRY_CODE.HONG_KONG]: [],
    [COUNTRY_CODE.INDONESIA]: [
        {
            id: 48,
            name: "Cycling",
            color: "red",
            subTopics: []
        }, {
            id: 60,
            name: "Swimming Pool Access",
            color: "red",
            subTopics: []
        }, {
            id: 63,
            name: "Test Sync",
            color: "red",
            subTopics: []
        }, {
            id: 65,
            name: "Independance Day Fitness Festival",
            color: "red",
            subTopics: []
        }, {
            id: 25,
            name: "HIIT",
            color: "red",
            subTopics: []
        }, {
            id: 24,
            name: "Mind & Body",
            color: "red",
            subTopics: []
        }, {
            id: 21,
            name: "Cardio",
            color: "red",
            subTopics: []
        }, {
            id: 22,
            name: "Strength and Conditioning",
            color: "red",
            subTopics: []
        }, {
            id: 23,
            name: "Dance",
            color: "red",
            subTopics: []
        }, {
            id: 26,
            name: "Feature Class",
            color: "red",
            subTopics: []
        }
    ],
    [COUNTRY_CODE.PHILIPPINES]: [
        {
            id: 42,
            name: "Outdoor Fitness",
            color: "red",
            subTopics: []
        }, {
            id: 61,
            name: "BOOK A TRAINER",
            color: "red",
            subTopics: []
        }, {
            id: 41,
            name: "Gym Floor Access",
            color: "red",
            subTopics: []
        }, {
            id: 39,
            name: "HIIT",
            color: "red",
            subTopics: []
        }, {
            id: 38,
            name: "Mind & Body",
            color: "red",
            subTopics: []
        }, {
            id: 49,
            name: "Cycling",
            color: "red",
            subTopics: []
        }, {
            id: 35,
            name: "Cardio",
            color: "red",
            subTopics: []
        }, {
            id: 36,
            name: "Strength and Conditioning",
            color: "red",
            subTopics: []
        }, {
            id: 37,
            name: "Dance",
            color: "red",
            subTopics: []
        }, {
            id: 40,
            name: "Feature Class",
            color: "red",
            subTopics: []
        }
    ],
    [COUNTRY_CODE.SINGAPORE]: [
        {
            id: 34,
            name: "Gym Floor Access",
            color: "red",
            subTopics: []
        }, {
            id: 32,
            name: "HIIT",
            color: "red",
            subTopics: []
        }, {
            id: 31,
            name: "Mind & Body",
            color: "red",
            subTopics: []
        }, {
            id: 58,
            name: "Cycling",
            color: "red",
            subTopics: []
        }, {
            id: 28,
            name: "Cardio",
            color: "red",
            subTopics: []
        }, {
            id: 29,
            name: "Strength and Conditioning",
            color: "red",
            subTopics: []
        }
    ],
    [COUNTRY_CODE.THAILAND]: [
        {
            id: 1,
            name: "Cardio",
            color: "red",
            subTopics: []
        }, {
            id: 2,
            name: "Strength and Conditioning",
            color: "red",
            subTopics: []
        }, {
            id: 3,
            name: "Dance",
            color: "red",
            subTopics: []
        }, {
            id: 4,
            name: "Mind & Body",
            color: "red",
            subTopics: []
        }, {
            id: 5,
            name: "HIIT",
            color: "red",
            subTopics: []
        }, {
            id: 6,
            name: "Feature Class",
            color: "red",
            subTopics: []
        }, {
            id: 57,
            name: "Cycling",
            color: "red",
            subTopics: []
        }, {
            id: 66,
            name: "Vitruvian Usage",
            color: "red",
            subTopics: []
        }
    ]
}