import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react"
import classNames from "classnames"
import { COLOR, useThemeContext } from "contexts/theme"
import { getAssetUrl } from "services/blockData"
import { useDependencies } from "services/hooks/useDependencies"
import { BlokComponent, StoryblokAsset, WithDependencies } from "services/model/storyblok"

interface StoryblokProps extends WithDependencies {
    fill_remaining_space?: boolean
    background_color?: COLOR
    text_color?: COLOR
    elements: Array<SbBlokData>
    background_image?: StoryblokAsset
}

/**
 * DONE
 */
export const CommonSection: BlokComponent = ({ blok }) => {
    const appBlok = blok as unknown as StoryblokProps
    const {getColor} = useThemeContext()
    useDependencies(appBlok.dependencies)
    

    const style: React.CSSProperties = {}
    let className = classNames("pb-5", "pt-[1rem]", "min-h-[130px]")

    const backgroundImageUrl = getAssetUrl(appBlok, "background_image")
    if (backgroundImageUrl) {
      style.backgroundImage = `url(${backgroundImageUrl})`;
    }

    if (appBlok.background_color) {
        style.backgroundColor = getColor(appBlok.background_color)
    }

    if (appBlok.text_color) {
        style.color = getColor(appBlok.text_color)
    }

    if (appBlok.fill_remaining_space) {
        className = classNames(className, "flex-auto", "max-w-[100%]", "overflow-x-auto")
    }

    return (
        <section {...storyblokEditable(blok)} style={style} className={className}>
            {appBlok.elements.map((blok) => (
                <StoryblokComponent blok={blok} key={blok._uid} parentDependencies={appBlok.dependencies} />
            ))}
        </section>
    )
}