import React, { useContext, useState } from "react";
import { Component } from "services/model/global";

////////////////////////////////////////
// Internal Types & Interfaces
////////////////////////////////////////
interface GofitContextState {
  menuOpen: boolean;
  setMenuOpen: (v: boolean) => void;
}

////////////////////////////////////////
// Exported Types & Interfaces
////////////////////////////////////////
export interface WithGofitContext {
  gofitContext: GofitContextState;
}

////////////////////////////////////////
// Context with default state
////////////////////////////////////////
// @ts-ignore
export const GofitContext = React.createContext<GofitContextState>();

export const GofitContextProvider: Component = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <GofitContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
      }}
    >
      {children}
    </GofitContext.Provider>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Context Provider & HOC
// Provider to include as the Parent before being able to use this context consumer
// High Order Component, callable at any place in the Component tree under the <..ContextProvider
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// This is a bit redundant as we could call useContext everywhere directly, but jest unit test
// requires this hook to be able to mock it.
export const useGofitContext = () => useContext(GofitContext);
