import { LocalizeProvider } from "react-localize-redux";
import { Component } from "services/model/global";
import { GofitContextProvider } from "./gofit";
import { ThemeContextProvider } from "./theme";
import { TranslationsContextProvider } from "./translations";

export const WithContexts: Component = ({ children }) => {
  return (
    <LocalizeProvider>
      <TranslationsContextProvider>
        <ThemeContextProvider>
          <GofitContextProvider>{children}</GofitContextProvider>
        </ThemeContextProvider>
      </TranslationsContextProvider>
    </LocalizeProvider>
  );
};
